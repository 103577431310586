import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormValues } from './types';
import { validationSchema } from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import { IPopupProps } from 'components/common/Popup/types';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';

interface Props extends IPopupProps {}

export const EditGeneralInfoPopup: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { user, refresh } = useUser();
  const { userDetailsControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const defaultValues = useMemo(
    () => ({ username: user.username, email: user.email }),
    [user.email, user.username],
  );

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    register,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = form;

  useEffect(() => {
    if (defaultValues.username && defaultValues.email) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const handlePopupClose = useCallback(() => {
    reset(defaultValues);
    onClose();
  }, [reset, defaultValues, onClose]);

  const handleUniqueFieldsValidation = useCallback(
    (message: string) => {
      if (message.endsWith('already exists.')) {
        if (message.startsWith('Username')) {
          setError('username', { message: t('form.errors.uniqueUsername') });
          return true;
        }

        if (message.startsWith('Email')) {
          setError('email', { message: t('form.errors.uniqueEmail') });
          return true;
        }
      }

      if (message.startsWith('Email address can be updated only')) {
        setError('email', {
          message: t('form.errors.emailUpdateLimitCurrentUser'),
        });
        return true;
      }

      return false;
    },
    [setError, t],
  );

  const onSubmit = handleSubmit(async ({ username, email }: FormValues) => {
    if (!username) return;

    try {
      await userDetailsControllerApi.update({
        userDetailsUpdateDto: {
          email,
        },
      });
      await refresh();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.updateProfile.editGeneralInfoPopup.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;

        const handled = handleUniqueFieldsValidation(message);

        if (!handled) toast.error(message);
      } else {
        openPopup({
          variant: NotificationPopupVariants.Error,
          title: t`components.errorPopup.title`,
          subtitle: t`components.errorPopup.subtitle`,
          secondaryButton: {
            text: t`buttons.cancel`,
            onClick: closePopup,
          },
        });
      }
    }
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    handlePopupClose();
  }, [isSubmitSuccessful, handlePopupClose]);

  return (
    <Popup isOpen={isOpen} onClose={handlePopupClose}>
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography
                variant="h2"
                textAlign="left"
                sx={{ mb: { desktop: 4, mobile: 5 } }}
              >
                {t('pages.updateProfile.editGeneralInfoPopup.title')}
              </Typography>
              <Typography
                variant="bodyM"
                textAlign="left"
                color="black.900"
                sx={{ display: 'block', mb: 3 }}
              >
                {t('pages.updateProfile.editGeneralInfoPopup.subtitle')}
              </Typography>
              <TextField
                label={t(
                  'pages.updateProfile.editGeneralInfoPopup.usernameInputLabel',
                )}
                fullWidth
                disabled
                sx={{ mb: 4 }}
                {...register('username')}
              />
              <TextField
                label={t(
                  'pages.updateProfile.editGeneralInfoPopup.emailInputLabel',
                )}
                fullWidth
                {...register('email')}
              />
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={!isDirty}
                type="submit"
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.save')}
              </CommonButton>
              <CommonButton variant="outlined" onClick={handlePopupClose}>
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
