import { Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';

import { PageContentWrapper, PageWrapper } from './styles';
import Tabs from 'components/common/Tabs';
import { TabType, TabsComponentVariant } from 'components/common/Tabs/types';
import routePaths from 'constants/routePaths';
import useUser from 'contexts/user';

export const Users: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const outlet = useOutlet();
  const { isSuperAdmin } = useUser();

  const tabs = useMemo(
    (): TabType[] => [
      ...(isSuperAdmin
        ? [
            {
              label: t('pages.users.tabs.admin'),
              value: routePaths.users.adminList.root,
            },
          ]
        : []),
      {
        label: t('pages.users.tabs.customer'),
        value: routePaths.users.customerList.root,
      },
    ],
    [isSuperAdmin, t],
  );

  useEffect(() => {
    if (pathname !== routePaths.users.root) return;

    if (isSuperAdmin) {
      navigate(routePaths.users.adminList.root);
      return;
    }

    navigate(routePaths.users.customerList.root);
  }, [pathname, isSuperAdmin, navigate]);

  return (
    <PageWrapper>
      <Typography variant="h1">{t('pages.users.title')}</Typography>
      <Tabs
        tabs={tabs}
        componentVariant={TabsComponentVariant.Tabs}
        sx={{ mt: { mobile: 4, desktop: 5 } }}
      />
      <PageContentWrapper>{outlet}</PageContentWrapper>
    </PageWrapper>
  );
};
