import { FC } from 'react';
import { Trans } from 'react-i18next';

import { WTLinkStyled } from 'components/shared/HistoryLog/styles';
import { UserInitiatedWindTurbineOnboardingDto } from 'openapi-api/admin-service';

export const OnboardingInitiatedTemplate: FC<
  UserInitiatedWindTurbineOnboardingDto & { locationId?: number }
> = ({ subjectUser, eventDateTime, locationId, publicId }) => {
  return (
    <Trans
      i18nKey="components.historyLog.templates.user.activities.onboardingInitiated"
      values={{ userId: subjectUser?.userId, eventDateTime }}
      components={{
        assetLink: (
          <WTLinkStyled locationId={locationId} publicId={publicId!} />
        ),
        bold: <strong />,
      }}
    />
  );
};
