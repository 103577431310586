import { useContext } from 'react';

import { AdminUserContext } from './AdminUserContext';
import { AdminUserProvider } from './AdminUserProvider';

const useAdminUserContext = () => {
  const context = useContext(AdminUserContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useAdminUserContext must be used within an AdminUserProvider',
    );
  }

  return context;
};

export { useAdminUserContext, AdminUserProvider };
