import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { InvestigationRequestStatus, ResetDashboardButton } from './components';
import { investigationDateTimeFormat } from './constants';
import CommonButton from 'components/common/CommonButton';
import { Link } from 'components/common/Link';
import { TextWithTooltip } from 'components/common/TextWithTooltip';
import routePaths from 'constants/routePaths';
import {
  RestorationRequestDto,
  RestorationRequestDtoStatusEnum,
} from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';

type GenerateColumnsPropsType = {
  t: TranslationFunction;
  isViewer?: boolean;
};

export const generateColumns = ({
  t,
  isViewer,
}: GenerateColumnsPropsType): GridColDef<RestorationRequestDto>[] => [
  {
    field: 'turbineName',
    headerName: t('pages.investigation.historyTable.columns.turbineName'),
    flex: 1,
    minWidth: 152,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value, row: { turbineName } }) => {
      return (
        <TextWithTooltip
          textProps={{ variant: 'h5', color: 'black.900' }}
          tooltipProps={{ placement: 'top' }}
          text={value || turbineName}
        />
      );
    },
  },
  {
    field: 'parkName',
    headerName: t('pages.investigation.historyTable.columns.parkName'),
    flex: 1,
    minWidth: 132,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value, row: { parkName } }) => (
      <Typography variant="bodyM" color="black.900" noWrap>
        {value || parkName}
      </Typography>
    ),
  },
  {
    field: 'customerName',
    headerName: t('masterAccount'),
    minWidth: 164,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value, row: { customerName } }) => (
      <TextWithTooltip
        textProps={{ variant: 'bodyM', color: 'black.900' }}
        tooltipProps={{ placement: 'top' }}
        text={value || customerName}
      />
    ),
  },
  {
    field: 'status',
    headerName: t('pages.investigation.historyTable.columns.status'),
    minWidth: 148,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value }) => <InvestigationRequestStatus status={value} />,
  },
  {
    field: 'timeFrame',
    headerName: t('pages.investigation.historyTable.columns.timeFrame'),
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row: { startDatetime, endDatetime } }) => (
      <Typography variant="bodyM" color="black.900">
        {`${dayjs(startDatetime).format(investigationDateTimeFormat)} - 
        ${dayjs(endDatetime).format(investigationDateTimeFormat)}`}
      </Typography>
    ),
  },
  {
    field: 'createDatetime',
    headerName: t('pages.investigation.historyTable.columns.requestDate'),
    minWidth: 200,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value, row: { createDatetime } }) => (
      <Typography variant="bodyM" color="black.900">
        {dayjs(value || createDatetime).format(investigationDateTimeFormat)}
      </Typography>
    ),
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 268,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row: { id = '', status, restorationId = '' } }) =>
      status === RestorationRequestDtoStatusEnum.COMPLETED && (
        <Box display="flex" gap={2}>
          {!isViewer && <ResetDashboardButton restorationId={restorationId} />}
          <Link to={routePaths.investigation.specific(id)}>
            <CommonButton size="small">
              {t('pages.investigation.historyTable.viewData')}
            </CommonButton>
          </Link>
        </Box>
      ),
  },
];
