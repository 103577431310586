import { Typography } from '@mui/material';
import { FC } from 'react';

import { BadgeWrapper } from './styles';
import { BadgeProps } from './types';

const Badge: FC<BadgeProps> = ({
  variant,
  size,
  text,
  onClick,
  dataTestId,
  sx,
}) => {
  return (
    <BadgeWrapper
      variant={variant}
      size={size}
      onClick={onClick}
      sx={sx}
      data-testid={dataTestId}
    >
      <Typography>{text}</Typography>
    </BadgeWrapper>
  );
};

export default Badge;
