import { Grid, styled } from '@mui/material';

export const ActionsWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isWTConnected',
})<{ isWTConnected?: boolean }>(({ theme, isWTConnected = true }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  ...(!isWTConnected && {
    // full width when Connect Master Account is visible and on new line [mobile]
    [theme.breakpoints.down(510)]: {
      flexGrow: 1,
    },
  }),
}));
