import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChartCard } from './components';
import {
  SelectParameter,
  getSelectCategoriesOptions,
  getSelectParameterOptions,
  panelsMapping,
} from './constants';
import { pickPanelsByCategories } from './helpers';
import {
  GridTable,
  GridTableWrapper,
  HeadWrapper,
  PageWrapper,
} from './styles';
import { investigationDateTimeFormat } from '../Investigation/components/History/constants';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import CommonButton from 'components/common/CommonButton';
import { Select } from 'components/common/Select';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';
import { SelectItem } from 'types/general';
import getDateTimeInUnixMs from 'utils/functions/getDateTimeInUnixMs';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const InvestigationData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { requestId } = useParams();
  const { restorationRequestControllerApi } = useApi();

  const [page, setPage] = useState(0);

  const selectParameterOptions: SelectItem[] = getSelectParameterOptions(t);
  const [parameter, setParameter] = useState<string>('');

  const selectCategoriesOptions: SelectItem[] = getSelectCategoriesOptions(
    parameter as SelectParameter,
    t,
  );
  const [categories, setCategories] = useState<string[]>([]);
  const onCategoriesChange = useCallback(
    (value: string) => {
      const categoryIndex = categories.indexOf(value);
      if (categoryIndex !== -1) {
        setCategories(categories.filter((category) => category !== value));
      } else setCategories([...categories, value]);
    },
    [categories],
  );

  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const handleOpenCategories = useCallback(() => {
    setIsCategoriesOpen(true);
  }, []);
  const handleCloseCategories = useCallback(() => {
    setIsCategoriesOpen(false);
  }, []);
  const selectCategoriesButtonsProps = useMemo(() => {
    return {
      onApply: handleCloseCategories,
      onClearAll: () => setCategories([]),
    };
  }, [handleCloseCategories]);
  const selectAllCategoriesProps = useMemo(() => {
    const allSelected = categories.length === selectCategoriesOptions.length;
    const onSelectAllClick = () => {
      if (allSelected) {
        setCategories([]);
      } else {
        setCategories(selectCategoriesOptions.map(({ value }) => value));
      }
    };
    return { allSelected, onSelectAllClick };
  }, [categories.length, selectCategoriesOptions]);

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));

  const getInvestigationData = useCallback(async () => {
    if (typeof requestId === 'undefined') {
      return;
    }
    try {
      const result = (
        await restorationRequestControllerApi.getRestorationRequestById({
          id: Number(requestId),
        })
      ).data;
      return result;
    } catch {}
  }, [requestId, restorationRequestControllerApi]);

  const { resource: investigationData } = useAsyncResource({
    fetchResource: getInvestigationData,
  });

  const pageSize = useMemo(() => {
    if (isDesktop) return 20;
    if (isTablet) return 10;
    return 5;
  }, [isDesktop, isTablet]);

  const currentTotal = useMemo(() => (page + 1) * pageSize, [page, pageSize]);

  const filteredWidgets = useMemo(() => {
    if (!parameter) return [];

    if (
      parameter === SelectParameter.TEMPERATURE ||
      parameter === SelectParameter.MECHANICAL
    ) {
      return panelsMapping?.[parameter] as {
        panelId: number;
        tKey: string;
      }[];
    }

    if (!categories.length) return [];

    return pickPanelsByCategories(
      panelsMapping?.[parameter as SelectParameter] as {
        [key: string]: { panelId: number; tKey: string }[];
      },
      categories,
    );
  }, [categories, parameter]);

  const currentWidgets = useMemo(() => {
    return filteredWidgets?.slice(0, currentTotal);
  }, [currentTotal, filteredWidgets]);

  const hasMore = useMemo(
    () => filteredWidgets.length > currentTotal,
    [currentTotal, filteredWidgets.length],
  );

  useEffect(() => {
    setCategories([]);
  }, [parameter]);

  return (
    <WindTurbinePanelsProvider
      dashboardType="ADMIN_INVESTIGATION"
      publicId={investigationData?.assetPublicId}
      restorationId={investigationData?.restorationId}
    >
      <PageWrapper>
        <HeadWrapper>
          <Box
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            justifyContent={isDesktop ? 'space-between' : 'unset'}
            gap={2}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={1.5}
              flexBasis="70%"
            >
              <Breadcrumbs
                items={[
                  {
                    label: t('pages.investigation.title'),
                    path: routePaths.investigation.root,
                  },
                  {
                    label: `${investigationData?.turbineName || ''}`,
                    path: routePaths.investigation.specific(
                      investigationData?.id || '',
                    ),
                  },
                ]}
              />
              <Typography variant="h2">
                {investigationData?.turbineName}
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={0.5}>
                <Grid container item spacing={1.5}>
                  <Grid item width="160px">
                    <Typography variant="bodyS" color="grey.900">
                      {`${t('masterAccountName')}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodySStrong" color="grey.900">
                      {investigationData?.customerName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={1.5}>
                  <Grid item width="160px">
                    <Typography variant="bodyS" color="grey.900">
                      {`${t('parkName')}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodySStrong" color="grey.900">
                      {investigationData?.parkName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={1.5} flexWrap="nowrap">
                  <Grid item width="160px" flexShrink={0}>
                    <Typography variant="bodyS" color="grey.900">
                      {`${t('timeFrame')}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodySStrong" color="grey.900">
                      {`${dayjs(investigationData?.startDatetime).format(
                        investigationDateTimeFormat,
                      )} - ${dayjs(investigationData?.endDatetime).format(
                        investigationDateTimeFormat,
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={isDesktop || isTablet ? 'row' : 'column'}
            gap={2.5}
          >
            <Select
              name="parameter"
              value={parameter}
              items={selectParameterOptions}
              label={t('form.parameter')}
              onItemClick={setParameter}
              sx={{
                width: {
                  mobile: '100%',
                  tablet: '50%',
                  desktop: 'calc(100% / 3)',
                },
              }}
            />
            <Select
              name="categories"
              value={categories}
              items={selectCategoriesOptions}
              label={t('form.categories')}
              open={isCategoriesOpen}
              onOpen={handleOpenCategories}
              onClose={handleCloseCategories}
              onItemClick={onCategoriesChange}
              sx={{
                width: {
                  mobile: '100%',
                  tablet: '50%',
                  desktop: 'calc(100% * 2 / 3)',
                },
              }}
              disabled={
                !parameter ||
                parameter === SelectParameter.TEMPERATURE ||
                parameter === SelectParameter.MECHANICAL
              }
              twoColumn={!!isDesktop}
              buttonsProps={selectCategoriesButtonsProps}
              selectAllProps={selectAllCategoriesProps}
              multiple
            />
          </Box>
        </HeadWrapper>
        <GridTableWrapper>
          {!currentWidgets.length ? (
            <Box flexGrow={1} alignSelf="center" alignContent="center">
              <Typography variant="h3" color="grey.600" textAlign="center">
                {t(
                  'pages.investigationData.specifyInvestigationParameterAndCategories',
                )}
              </Typography>
            </Box>
          ) : (
            <>
              <GridTable>
                {currentWidgets.map(({ panelId, tKey }) => (
                  <ChartCard
                    title={t(tKey)}
                    panelId={panelId}
                    fromDate={getDateTimeInUnixMs(
                      investigationData?.startDatetime as string,
                    )}
                    toDate={getDateTimeInUnixMs(
                      investigationData?.endDatetime as string,
                    )}
                    key={panelId}
                  />
                ))}
              </GridTable>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                {hasMore && (
                  <CommonButton
                    variant="outlined"
                    color="secondary"
                    sx={{ backgroundColor: 'transparent' }}
                    onClick={() => setPage(page + 1)}
                  >
                    {t('pages.viewTags.loadMore')}
                  </CommonButton>
                )}
              </Box>
            </>
          )}
        </GridTableWrapper>
      </PageWrapper>
    </WindTurbinePanelsProvider>
  );
};

export default InvestigationData;
