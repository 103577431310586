import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbItem } from 'components/common/Breadcrumbs';
import { UserHistoryLog } from 'components/shared/UserHistoryLog';
import routePaths from 'constants/routePaths';
import { useMasterAccountContext } from 'contexts/masterAccount';

export const MasterAccountHistoryLog: FC = () => {
  const { t } = useTranslation();

  const { masterAccount } = useMasterAccountContext();

  const breadcrumbs = useMemo(
    (): BreadcrumbItem[] => [
      {
        label: t('pages.users.title'),
        path: routePaths.users.customerList.root,
      },
      {
        label: masterAccount?.username || '',
        path: routePaths.users.customerList.profile(masterAccount?.id!).root,
      },
      {
        label: t('components.historyLog.title'),
        path: routePaths.users.customerList.profile(masterAccount?.id!)
          .historyLog,
      },
    ],
    [masterAccount, t],
  );

  return (
    <UserHistoryLog userDetails={masterAccount} breadcrumbs={breadcrumbs} />
  );
};
