import { useCallback } from 'react';

import useApi from 'contexts/api';

export const useUniqueEntityValidators = () => {
  const { customerControllerApi, locationControllerApi } = useApi();

  const isUsernameExist = useCallback(
    async (username: string) => {
      try {
        const existingUser = (
          await customerControllerApi.getCustomerByName({
            name: username.trim(),
          })
        ).data;
        if (existingUser) return true;
        return false;
      } catch {
        return false;
      }
    },
    [customerControllerApi],
  );

  const isParkNameExist = useCallback(
    async (parkName: string) => {
      try {
        const existingPark = (
          await locationControllerApi.getLocationByName({
            name: parkName.trim(),
          })
        ).data;
        if (existingPark) return true;
        return false;
      } catch {
        return false;
      }
    },
    [locationControllerApi],
  );

  return { isUsernameExist, isParkNameExist };
};
