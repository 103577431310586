import { styled } from '@mui/material';

export const InfoBlockGrid = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isAdmin',
})<{ isAdmin?: boolean }>(({ theme, isAdmin }) => ({
  display: 'grid',
  gridTemplateColumns: isAdmin ? '160px auto' : '180px auto',
  gap: theme.spacing(2, 3),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
  },
}));
