import { styled, Grid } from '@mui/material';

export const WindTurbineControlWrapper = styled(Grid)(({ theme }) => ({
  height: '100%',

  flexDirection: 'row',
  flexWrap: 'nowrap',
  backgroundColor: theme.palette.grey[50],
  borderRadius: '25px',
}));
