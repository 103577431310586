import { createContext } from 'react';

import { UserDetailsDto } from 'openapi-api/admin-service';

export interface IMasterAccountContext {
  masterAccount?: UserDetailsDto;
  isLoading: boolean;
  fetch: () => Promise<void>;
}

export const MasterAccountContext = createContext<IMasterAccountContext>(null!);
