const windTurbinePaths = (rootPath: string, publicId: string | number) => {
  const wtRootPath = `${rootPath}/wind-turbines/${publicId}`;

  return {
    root: wtRootPath,
    general: `${wtRootPath}/general`,
    statuses: `${wtRootPath}/statuses`,
    streaming: `${wtRootPath}/streaming`,
    errorReport: `${wtRootPath}/errorReport`,
    historyLog: {
      root: `${wtRootPath}/history`,
      specific: (type: string) => `${wtRootPath}/history/${type}`,
    },
  };
};

const routePaths = {
  root: '/',
  parks: {
    root: '/parks',
    specific: (locationId: string | number) => {
      const parkRootPath = `${routePaths.parks.root}/${locationId}`;

      return {
        root: parkRootPath,
        windTurbine: (publicId: string | number) =>
          windTurbinePaths(parkRootPath, publicId),
      };
    },
    unassigned: () => {
      const unassignedRootPath = `${routePaths.parks.root}/unassigned`;
      return {
        root: unassignedRootPath,
        windTurbine: (publicId: string | number) =>
          windTurbinePaths(unassignedRootPath, publicId),
      };
    },
  },
  login: '/login',
  emailVerification: (verificationToken: string) =>
    `/email-verification/${verificationToken}`,
  reports: '/reports',
  users: {
    root: '/users',
    adminList: {
      root: '/users/admin-portal',
      profile: (userId: string | number) => ({
        root: `/users/admin-portal/${userId}`,
        historyLog: `/users/admin-portal/${userId}/history`,
      }),
    },
    customerList: {
      root: '/users/user-portal',
      profile: (userId: string | number) => ({
        root: `/users/user-portal/${userId}`,
        historyLog: `/users/user-portal/${userId}/history`,
      }),
    },
  },
  design: {
    main: '/design',
    specificWT: (publicId: string | number) => `/design/${publicId}`,
  },
  notifications: {
    root: '/notifications',
    specific: (type: string) => `/notifications/${type}`,
  },
  investigation: {
    root: '/investigation',
    prefilled: (
      customerName: string,
      locationName: string,
      assetName: string,
      from: string,
      to: string,
    ) =>
      `/investigation?customerName=${customerName}&locationName=${locationName}&assetName=${assetName}&from=${from}&to=${to}`,
    specific: (requestId: string | number) => `/investigation/${requestId}`,
  },
  updateProfile: '/update-profile',
  viewTags: (publicId: string, errorTimestamp: string) =>
    `/view-tags/${publicId}/${errorTimestamp}`,
};

export default routePaths;
