import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EditAdminUserPopup } from './components';
import { BreadcrumbItem, Breadcrumbs } from 'components/common/Breadcrumbs';
import { MenuItemType } from 'components/common/DropdownMenu/DropdownMenu';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { UserCredentials } from 'components/shared';
import {
  ProfileContent,
  ProfilePageWrapper,
} from 'components/shared/ProfilePage';
import routePaths from 'constants/routePaths';
import { useAdminUserContext } from 'contexts/adminUser';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';

export const AdminUserProfile: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { adminUserControllerApi } = useApi();
  const { adminUser, fetch, isLoading } = useAdminUserContext();
  const { user: currentUser } = useUser();

  const { openPopup, closePopup } = useResponsePopup();
  const [editPopupOpen, setEditPopupOpen] = useState<boolean>(false);

  const breadcrumbItems = useMemo((): BreadcrumbItem[] => {
    return [
      { label: t('pages.users.title'), path: routePaths.users.adminList.root },
      ...(!isLoading && !!adminUser?.id
        ? [
            {
              label: adminUser.username as string,
              path: routePaths.users.adminList.profile(adminUser?.id).root,
            },
          ]
        : []),
    ];
  }, [t, isLoading, adminUser]);

  const resetPassword = useCallback(async () => {
    if (typeof adminUser?.id === 'undefined') return;

    try {
      const { data } = await adminUserControllerApi.updatePassword3({
        id: adminUser.id,
      });

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.resetPasswordPopup.successTitle'),
        subtitle: t('pages.users.resetPasswordPopup.successSubtitle'),
        children: (
          <UserCredentials
            variant="admin"
            username={adminUser.username}
            password={data.password}
          />
        ),
        primaryButton: {
          text: t('continueSession'),
          onClick: closePopup,
        },
      });
    } catch {}
  }, [adminUserControllerApi, adminUser, openPopup, closePopup, t]);

  const deleteAdminUser = useCallback(async () => {
    if (typeof adminUser?.id === 'undefined') return;

    try {
      await adminUserControllerApi.deleteAdminUser({
        id: adminUser.id,
      });
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.deleteUserPopup.successTitle'),
        primaryButton: {
          onClick: () => {
            closePopup();
            navigate(routePaths.users.adminList.root);
          },
          text: t('buttons.continueSession'),
        },
      });
    } catch {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.deleteUserPopup.failTitle'),
        subtitle: t('pages.users.deleteUserPopup.failSubtitle'),
        secondaryButton: {
          onClick: closePopup,
          text: t('back'),
        },
      });
    }
  }, [adminUser, adminUserControllerApi, openPopup, closePopup, navigate, t]);

  const restoreAccount = useCallback(async () => {
    if (typeof adminUser?.id === 'undefined') return;

    try {
      await adminUserControllerApi.restoreAdminUser({
        id: adminUser.id,
      });
      await fetch();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.restoreUserPopup.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.restoreUserPopup.failTitle'),
        subtitle: t('pages.users.restoreUserPopup.failSubtitle'),
        secondaryButton: {
          onClick: closePopup,
          text: t('back'),
        },
      });
    }
  }, [adminUser?.id, adminUserControllerApi, fetch, openPopup, closePopup, t]);

  const onEditPopupClose = useCallback(() => {
    setEditPopupOpen(false);
    fetch();
  }, [fetch]);

  const onResetPasswordClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.resetPasswordPopup.title'),
      subtitle: t('pages.users.resetPasswordPopup.subtitle'),
      primaryButton: {
        onClick: resetPassword,
        text: t('pages.users.resetPasswordPopup.primaryButtonText'),
      },
      secondaryButton: {
        onClick: closePopup,
        text: t('buttons.cancel'),
      },
    });
  }, [openPopup, closePopup, t, resetPassword]);

  const onDeleteAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.deleteUserPopup.adminTitle'),
      subtitle: t('pages.users.deleteUserPopup.adminSubtitle'),
      primaryButton: {
        onClick: deleteAdminUser,
        text: t('pages.users.deleteUserPopup.primaryButtonText'),
      },
      secondaryButton: {
        onClick: closePopup,
        text: t('buttons.cancel'),
      },
    });
  }, [deleteAdminUser, openPopup, closePopup, t]);

  const onRestoreAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.restoreUserPopup.title'),
      subtitle: t('pages.users.restoreUserPopup.adminSubtitle'),
      primaryButton: {
        text: t('pages.users.restoreUserPopup.submitButtonText'),
        onClick: restoreAccount,
      },
      secondaryButton: {
        text: t('buttons.cancel'),
        onClick: closePopup,
      },
    });
  }, [t, openPopup, closePopup, restoreAccount]);

  const actionsMenuItems = useMemo((): MenuItemType[] => {
    if (
      typeof adminUser?.id === 'undefined' ||
      adminUser.id === currentUser.id
    ) {
      return [];
    }

    return [
      {
        label: t('pages.users.resetPassword'),
        onClick: onResetPasswordClick,
      },
      ...(adminUser?.status === UserDetailsDtoStatusEnum.ACTIVE
        ? [
            {
              label: t('pages.users.deleteAccount'),
              onClick: onDeleteAccountClick,
              color: 'error.main',
            },
          ]
        : [
            {
              label: t('pages.users.restoreAccount'),
              onClick: onRestoreAccountClick,
            },
          ]),
    ];
  }, [
    t,
    adminUser,
    currentUser,
    onDeleteAccountClick,
    onRestoreAccountClick,
    onResetPasswordClick,
  ]);

  return (
    <ProfilePageWrapper>
      <Breadcrumbs items={breadcrumbItems} />
      <ProfileContent
        userDetails={adminUser}
        onEditUserClick={() => setEditPopupOpen(true)}
        actionsMenuItems={actionsMenuItems}
      />
      {editPopupOpen && (
        <EditAdminUserPopup isOpen onClose={onEditPopupClose} />
      )}
    </ProfilePageWrapper>
  );
};
