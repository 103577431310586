import { FC } from 'react';
import { Trans } from 'react-i18next';

import { ErrorCodeResolvedDto } from 'openapi-api/admin-service';

export const ErrorCodeResolvedTemplate: FC<ErrorCodeResolvedDto> = ({
  assetNameForAdmin,
  plcErrorDescription,
  plcErrorType,
  eventDateTime,
}) => {
  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.statusesAndErrors.errorCodeResolved"
      values={{
        assetName: assetNameForAdmin,
        errorDescription: plcErrorDescription,
        errorCode: plcErrorType,
        eventDateTime,
      }}
      components={{ bold: <strong /> }}
    />
  );
};
