import { Divider, Grid, Typography } from '@mui/material';
import { ChangeEvent, FC, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TurbineAnimation, TurbineAnimationStatus } from './components';
import { WindTurbineControlWrapper } from './styles';
import Switch from 'components/common/form/Switch';

interface WindTurbineControlProps {
  name: string;
  value?: boolean;
  onClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  errorStatus?: boolean;
  withAnimation?: boolean;
  dataTestId?: string;
  children?: ReactElement;
}

export const WindTurbineControl: FC<WindTurbineControlProps> = ({
  name,
  value,
  onClick,
  disabled,
  errorStatus,
  withAnimation,
  dataTestId,
  children,
}) => {
  const { t } = useTranslation();

  const animationStatus = useMemo(() => {
    if (value && !errorStatus) return TurbineAnimationStatus.ACTIVE;
    if (value && errorStatus) return TurbineAnimationStatus.ERROR_ACTIVE;
    if (!value && errorStatus) return TurbineAnimationStatus.ERROR_INACTIVE;
    return TurbineAnimationStatus.INACTIVE;
  }, [errorStatus, value]);

  return (
    <WindTurbineControlWrapper container>
      {withAnimation && <TurbineAnimation status={animationStatus} />}
      <Grid
        container
        item
        direction="column"
        p={3}
        pl={withAnimation ? 2.5 : 3}
      >
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
          wrap="nowrap"
        >
          <Typography variant="h4" color="black.600">
            {name}
          </Typography>
          <Switch
            checked={value}
            onChange={onClick}
            disabled={disabled}
            data-testid={dataTestId}
          />
        </Grid>
        <Divider sx={{ mt: 1.5, mb: 2 }} />
        <Grid
          container
          item
          alignItems="center"
          maxWidth="100%"
          flexWrap="nowrap"
        >
          <Typography variant="subheading" color="black.600" mr={1.5}>
            {t('pages.turbine.status')}
          </Typography>
          {children}
        </Grid>
      </Grid>
    </WindTurbineControlWrapper>
  );
};
