/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MasterAccountCreateRequestDto } from '../models';
// @ts-ignore
import { MasterAccountCreateResponseDto } from '../models';
// @ts-ignore
import { MasterAccountCustomerUserDto } from '../models';
// @ts-ignore
import { PageUserDetailsDto } from '../models';
// @ts-ignore
import { UserDetailsDto } from '../models';
// @ts-ignore
import { UserUpdatePasswordResponseDto } from '../models';
/**
 * MasterAccountControllerApi - axios parameter creator
 * @export
 */
export const MasterAccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MasterAccountCreateRequestDto} masterAccountCreateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMasterAccount: async (masterAccountCreateRequestDto: MasterAccountCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterAccountCreateRequestDto' is not null or undefined
            assertParamExists('createMasterAccount', 'masterAccountCreateRequestDto', masterAccountCreateRequestDto)
            const localVarPath = `/master-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(masterAccountCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMasterAccount: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMasterAccount', 'id', id)
            const localVarPath = `/master-account/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterAccountCustomerUserDetailsById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMasterAccountCustomerUserDetailsById', 'id', id)
            const localVarPath = `/master-account/customer-user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterAccountDetails: async (status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>, search?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterAccountDetailsById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMasterAccountDetailsById', 'id', id)
            const localVarPath = `/master-account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreMasterAccount: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restoreMasterAccount', 'id', id)
            const localVarPath = `/master-account/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterAccountPassword: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterAccountPassword', 'id', id)
            const localVarPath = `/master-account/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterAccountControllerApi - functional programming interface
 * @export
 */
export const MasterAccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterAccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MasterAccountCreateRequestDto} masterAccountCreateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMasterAccount(masterAccountCreateRequestDto: MasterAccountCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterAccountCreateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMasterAccount(masterAccountCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMasterAccount(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMasterAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterAccountCustomerUserDetailsById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterAccountCustomerUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterAccountCustomerUserDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterAccountDetails(status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterAccountDetails(status, search, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterAccountDetailsById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterAccountDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreMasterAccount(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreMasterAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterAccountPassword(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUpdatePasswordResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterAccountPassword(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MasterAccountControllerApi - factory interface
 * @export
 */
export const MasterAccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterAccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MasterAccountControllerApiCreateMasterAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMasterAccount(requestParameters: MasterAccountControllerApiCreateMasterAccountRequest, options?: AxiosRequestConfig): AxiosPromise<MasterAccountCreateResponseDto> {
            return localVarFp.createMasterAccount(requestParameters.masterAccountCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterAccountControllerApiDeleteMasterAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMasterAccount(requestParameters: MasterAccountControllerApiDeleteMasterAccountRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMasterAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterAccountControllerApiGetMasterAccountCustomerUserDetailsByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterAccountCustomerUserDetailsById(requestParameters: MasterAccountControllerApiGetMasterAccountCustomerUserDetailsByIdRequest, options?: AxiosRequestConfig): AxiosPromise<MasterAccountCustomerUserDto> {
            return localVarFp.getMasterAccountCustomerUserDetailsById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterAccountControllerApiGetMasterAccountDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterAccountDetails(requestParameters: MasterAccountControllerApiGetMasterAccountDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageUserDetailsDto> {
            return localVarFp.getMasterAccountDetails(requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterAccountControllerApiGetMasterAccountDetailsByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterAccountDetailsById(requestParameters: MasterAccountControllerApiGetMasterAccountDetailsByIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserDetailsDto> {
            return localVarFp.getMasterAccountDetailsById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterAccountControllerApiRestoreMasterAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreMasterAccount(requestParameters: MasterAccountControllerApiRestoreMasterAccountRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.restoreMasterAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MasterAccountControllerApiUpdateMasterAccountPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterAccountPassword(requestParameters: MasterAccountControllerApiUpdateMasterAccountPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<UserUpdatePasswordResponseDto> {
            return localVarFp.updateMasterAccountPassword(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMasterAccount operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiCreateMasterAccountRequest
 */
export interface MasterAccountControllerApiCreateMasterAccountRequest {
    /**
     * 
     * @type {MasterAccountCreateRequestDto}
     * @memberof MasterAccountControllerApiCreateMasterAccount
     */
    readonly masterAccountCreateRequestDto: MasterAccountCreateRequestDto
}

/**
 * Request parameters for deleteMasterAccount operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiDeleteMasterAccountRequest
 */
export interface MasterAccountControllerApiDeleteMasterAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterAccountControllerApiDeleteMasterAccount
     */
    readonly id: number
}

/**
 * Request parameters for getMasterAccountCustomerUserDetailsById operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiGetMasterAccountCustomerUserDetailsByIdRequest
 */
export interface MasterAccountControllerApiGetMasterAccountCustomerUserDetailsByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterAccountControllerApiGetMasterAccountCustomerUserDetailsById
     */
    readonly id: number
}

/**
 * Request parameters for getMasterAccountDetails operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiGetMasterAccountDetailsRequest
 */
export interface MasterAccountControllerApiGetMasterAccountDetailsRequest {
    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>}
     * @memberof MasterAccountControllerApiGetMasterAccountDetails
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>

    /**
     * 
     * @type {string}
     * @memberof MasterAccountControllerApiGetMasterAccountDetails
     */
    readonly search?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MasterAccountControllerApiGetMasterAccountDetails
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MasterAccountControllerApiGetMasterAccountDetails
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MasterAccountControllerApiGetMasterAccountDetails
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getMasterAccountDetailsById operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiGetMasterAccountDetailsByIdRequest
 */
export interface MasterAccountControllerApiGetMasterAccountDetailsByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterAccountControllerApiGetMasterAccountDetailsById
     */
    readonly id: number
}

/**
 * Request parameters for restoreMasterAccount operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiRestoreMasterAccountRequest
 */
export interface MasterAccountControllerApiRestoreMasterAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterAccountControllerApiRestoreMasterAccount
     */
    readonly id: number
}

/**
 * Request parameters for updateMasterAccountPassword operation in MasterAccountControllerApi.
 * @export
 * @interface MasterAccountControllerApiUpdateMasterAccountPasswordRequest
 */
export interface MasterAccountControllerApiUpdateMasterAccountPasswordRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterAccountControllerApiUpdateMasterAccountPassword
     */
    readonly id: number
}

/**
 * MasterAccountControllerApi - object-oriented interface
 * @export
 * @class MasterAccountControllerApi
 * @extends {BaseAPI}
 */
export class MasterAccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {MasterAccountControllerApiCreateMasterAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public createMasterAccount(requestParameters: MasterAccountControllerApiCreateMasterAccountRequest, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).createMasterAccount(requestParameters.masterAccountCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterAccountControllerApiDeleteMasterAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public deleteMasterAccount(requestParameters: MasterAccountControllerApiDeleteMasterAccountRequest, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).deleteMasterAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterAccountControllerApiGetMasterAccountCustomerUserDetailsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public getMasterAccountCustomerUserDetailsById(requestParameters: MasterAccountControllerApiGetMasterAccountCustomerUserDetailsByIdRequest, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).getMasterAccountCustomerUserDetailsById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterAccountControllerApiGetMasterAccountDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public getMasterAccountDetails(requestParameters: MasterAccountControllerApiGetMasterAccountDetailsRequest = {}, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).getMasterAccountDetails(requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterAccountControllerApiGetMasterAccountDetailsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public getMasterAccountDetailsById(requestParameters: MasterAccountControllerApiGetMasterAccountDetailsByIdRequest, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).getMasterAccountDetailsById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterAccountControllerApiRestoreMasterAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public restoreMasterAccount(requestParameters: MasterAccountControllerApiRestoreMasterAccountRequest, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).restoreMasterAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MasterAccountControllerApiUpdateMasterAccountPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterAccountControllerApi
     */
    public updateMasterAccountPassword(requestParameters: MasterAccountControllerApiUpdateMasterAccountPasswordRequest, options?: AxiosRequestConfig) {
        return MasterAccountControllerApiFp(this.configuration).updateMasterAccountPassword(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
