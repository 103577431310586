import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import NotificationsListWrapper from './styles/NotificationsListWrapper';
import NotificationMessage from '../../../../common/Notifications/NotificationMessage';
import { ConnectMasterAccount } from 'components/common/ConnectMasterAccount';
import { NotificationDto } from 'openapi-api/admin-service';

export interface Props {
  notifications: NotificationDto[];
  readNotification: (notification: NotificationDto) => void;
}
export const NotificationsList: FC<Props> = ({
  notifications,
  readNotification,
}) => {
  const { type } = useParams();
  const [addUserId, setAddUserId] = useState<string>();

  const handleCloseConnectMasterAccount = useCallback(() => {
    setAddUserId(undefined);
  }, []);

  return (
    <>
      <NotificationsListWrapper>
        {notifications.map((notification) => (
          <NotificationMessage
            key={`${notification?.id}-${type}`}
            notification={notification}
            onClick={readNotification}
            setAddUserId={setAddUserId}
            extended={true}
          />
        ))}
      </NotificationsListWrapper>
      {addUserId && (
        <ConnectMasterAccount
          onClose={handleCloseConnectMasterAccount}
          onSuccess={handleCloseConnectMasterAccount}
          publicId={addUserId}
        />
      )}
    </>
  );
};
