import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';

import { HeadBlock, WindTurbinePageTabs } from './components';
import { PageWrapper, TabsContent } from './styles';
import CommonButton from 'components/common/CommonButton';
import { RolePermissionWrapper } from 'components/shared';
import routePaths from 'constants/routePaths';
import { WindTurbineProvider } from 'contexts/WindTurbine';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';

export const WindTurbine = () => {
  const { t } = useTranslation();
  const { locationId, publicId } = useParams();
  const navigate = useNavigate();
  const outlet = useOutlet();

  const onHistoryLogButtonClick = useCallback(() => {
    if (!publicId) return;

    if (locationId) {
      navigate(
        routePaths.parks.specific(locationId).windTurbine(publicId).historyLog
          .root,
      );
      return;
    }

    navigate(
      routePaths.parks.unassigned().windTurbine(publicId).historyLog.root,
    );
  }, [locationId, publicId, navigate]);

  return (
    <WindTurbineProvider>
      <PageWrapper>
        <HeadBlock />
        <Box
          display="flex"
          flexDirection={{ mobile: 'column-reverse', tablet: 'row' }}
          justifyContent="space-between"
          alignItems={{ tablet: 'center' }}
          gap={{ mobile: 5, tablet: 2.5, desktop: 3 }}
          mt={{ mobile: 2, tablet: 5, desktop: 6.5 }}
        >
          <WindTurbinePageTabs />
          <RolePermissionWrapper
            disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
          >
            <CommonButton
              onClick={onHistoryLogButtonClick}
              size="small"
              sx={{ flexGrow: { mobile: 1, tablet: 0 } }}
            >
              {t('components.historyLog.title')}
            </CommonButton>
          </RolePermissionWrapper>
        </Box>
        <TabsContent>{outlet}</TabsContent>
      </PageWrapper>
    </WindTurbineProvider>
  );
};
