import { UserCreateRequestDtoUserRoleEnum } from 'openapi-api/admin-service';
import { SelectItem, TranslationFunction } from 'types/general';

export const getAdminUserRoleSelectItems = (
  t: TranslationFunction,
): SelectItem[] => [
  {
    label: t(
      `userRoles.${UserCreateRequestDtoUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN}`,
    ),
    value: UserCreateRequestDtoUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN,
  },
  {
    label: t(
      `userRoles.${UserCreateRequestDtoUserRoleEnum.ADMIN_PORTAL_ADMIN}`,
    ),
    value: UserCreateRequestDtoUserRoleEnum.ADMIN_PORTAL_ADMIN,
  },
  {
    label: t(
      `userRoles.${UserCreateRequestDtoUserRoleEnum.ADMIN_PORTAL_VIEWER}`,
    ),
    value: UserCreateRequestDtoUserRoleEnum.ADMIN_PORTAL_VIEWER,
  },
];
