import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useApi from '../../../contexts/api';
import { SelectItem } from '../../../types/general';
import { useAsyncResource } from '../../../utils/hooks/useAsyncResource';
import { FormSelect } from '../form/Select';
import TextField from '../form/TextField';
import OrHorizontal from '../OrHorizontal';
import { CustomerDto } from 'openapi-api/admin-service';

export const SelectOrCreateCustomer = () => {
  const { customerControllerApi } = useApi();
  const { t } = useTranslation();
  const { setValue, clearErrors } = useFormContext();

  const getCustomers = useCallback(async () => {
    try {
      const response = await customerControllerApi.allCustomers({
        status: ['ACTIVE'],
      });

      return response.data as CustomerDto[];
    } catch {}
  }, [customerControllerApi]);

  const { resource: customers } = useAsyncResource({
    fetchResource: getCustomers,
  });

  const selectItems: SelectItem[] = useMemo(
    () =>
      customers?.map(({ name }) => ({
        label: name as string,
        value: name as string,
      })) || [],
    [customers],
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <FormSelect
        name="existingCustomer"
        label={t('masterAccountName')}
        items={selectItems}
        fullWidth
        dataTestId="selectCustomer"
        onItemClick={() => {
          setValue('newCustomer', '');
          clearErrors('newCustomer');
        }}
      />
      <OrHorizontal />
      <Typography variant="h4">
        {t('components.assetOnboarding.createNewMasterAccount')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={1.5}>
        <TextField
          label={t('masterAccountName')}
          fullWidth
          onInput={() => {
            setValue('existingCustomer', '');
            clearErrors('existingCustomer');
          }}
          testId="newCustomer"
          name="newCustomer"
        />
      </Box>
    </Box>
  );
};
