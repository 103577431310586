import { Dialog as DialogMui, styled } from '@mui/material';

import { NotificationPopupVariants } from '../types';
import publicPath from 'utils/publicPath';

export const Dialog = styled(DialogMui)<{
  variant?: NotificationPopupVariants;
}>(({ theme, variant }) => ({
  '& .MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '540px',
    padding: theme.spacing(8, 6),
    borderRadius: '25px',
    textAlign: 'center',
    whiteSpace: 'pre-line',

    ...(variant === NotificationPopupVariants.Success && {
      backgroundColor: theme.palette.black['600'],
      backgroundImage: `url(${publicPath('/images/success-bg.png')})`,
      backgroundSize: 'cover',

      '& .MuiTypography-h1, & .MuiTypography-bodyM': {
        color: theme.palette.white.main,
      },
    }),

    ...(variant === NotificationPopupVariants.Warning && {
      backgroundColor: 'white',

      '& .MuiTypography-h1': {
        color: theme.palette.black[900],
      },

      '& .MuiTypography-bodyM': {
        color: theme.palette.grey[800],
      },
    }),

    [theme.breakpoints.down('desktop')]: {
      height: '100%',
      maxHeight: '100%',
      width: '100%',
      maxWidth: 'unset',
      minWidth: 'unset',
      margin: 0,
      padding: theme.spacing(6.5, 12.5, 5),
      borderRadius: 0,
    },

    [theme.breakpoints.down('tablet')]: {
      padding: theme.spacing(13, 2.5, 5),
    },
  },
}));
