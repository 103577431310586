import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';

import { defaultValues } from './constants';
import { FormValues } from './types';
import { validationSchema } from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import { FormSelect } from 'components/common/form/Select';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import { IPopupProps } from 'components/common/Popup/types';
import { UserCredentials } from 'components/shared';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import { UserCreateRequestDtoUserRoleEnum } from 'openapi-api/admin-service';
import { SelectItem } from 'types/general';
import { getAdminUserRoleSelectItems } from 'utils/functions/getAdminUserRoleSelectItems';

interface Props extends IPopupProps {}

export const AddAdminUserPopup: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { adminUserControllerApi } = useApi();
  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });

  const { openPopup, closePopup } = useResponsePopup();

  const {
    handleSubmit,
    reset,
    register,
    formState: { dirtyFields, isSubmitSuccessful },
    setError,
    watch,
  } = form;

  const usernameValue = watch('username');
  const userRoleValue = watch('userRole');

  const userRoleItems = useMemo(
    (): SelectItem[] => getAdminUserRoleSelectItems(t),
    [t],
  );

  const isSubmitButtonDisabled = useMemo(
    () => !usernameValue || !userRoleValue,
    [usernameValue, userRoleValue],
  );

  const onSubmit = handleSubmit(
    async ({ username, email, userRole }: FormValues) => {
      try {
        const data = (
          await adminUserControllerApi.createAdminUser({
            userCreateRequestDto: {
              username,
              email: email || undefined,
              userRole: userRole as UserCreateRequestDtoUserRoleEnum,
            },
          })
        ).data;

        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t('pages.users.adminUsers.addUserPopup.successTitle'),
          subtitle: dirtyFields.email
            ? t('pages.users.adminUsers.addUserPopup.successSubtitle')
            : undefined,
          children: (
            <UserCredentials
              variant="admin"
              username={data.username as string}
              password={data.password as string}
            />
          ),
          primaryButton: {
            text: t('continueSession'),
            onClick: closePopup,
          },
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.data.message) {
          const message: string = e.response.data.message;

          if (message === `Username ${username} already exists.`) {
            setError('username', { message: t('form.errors.uniqueUsername') });
            return;
          }

          if (message === `Email ${email} already exists.`) {
            setError('email', { message: t('form.errors.uniqueEmail') });
            return;
          }
        }
      }
    },
  );

  const handlePopupClose = useCallback(() => {
    reset(defaultValues);
    onClose();
  }, [reset, onClose]);

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    handlePopupClose();
  }, [isSubmitSuccessful, handlePopupClose]);

  return (
    <Popup isOpen={isOpen} onClose={handlePopupClose}>
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography variant="h2" textAlign="left" sx={{ mb: 4 }}>
                {t('pages.users.adminUsers.addUserPopup.title')}
              </Typography>
              <Typography
                variant="bodyM"
                textAlign="left"
                sx={{ display: 'block', mb: 3 }}
              >
                {t('pages.users.adminUsers.addUserPopup.subtitle')}
              </Typography>
              <Box display="flex" flexDirection="column" gap={4}>
                <TextField
                  label={t('username')}
                  fullWidth
                  {...register('username')}
                  testId="username"
                />
                <TextField
                  label={t('emailAddress')}
                  fullWidth
                  {...register('email')}
                  testId="email"
                />
                <FormSelect
                  name="userRole"
                  label={t('role')}
                  items={userRoleItems}
                  fullWidth
                />
              </Box>
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={isSubmitButtonDisabled}
                type="submit"
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.submit')}
              </CommonButton>
              <CommonButton variant="outlined" onClick={handlePopupClose}>
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
