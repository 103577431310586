import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { defaultValues } from './constants';
import {
  ConnectMasterAccountDialogWrapper,
  ButtonsWrapper,
  CurrentStepWrapper,
  FormWrapper,
} from './styles';
import { FormValues } from './types';
import { validationSchemas } from './validationSchema';
import useApi from '../../../contexts/api';
import CommonButton from '../CommonButton';
import { NotificationPopupVariants } from '../NotificationPopup/types';
import { SelectOrCreateCustomer } from '../SelectOrCreateCustomer';
import { SelectOrCreatePark } from '../SelectOrCreatePark';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { UserCredentials } from 'components/shared';
import useResponsePopup from 'contexts/responsePopup';
import { MasterAccountCreateResponseDto } from 'openapi-api/admin-service';
import { useUniqueEntityValidators } from 'utils/hooks/useUniqueEntityValidators';

type Props = {
  publicId: string;
  onClose: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  onSuccess: (e?: React.MouseEvent<Element, MouseEvent>) => void;
};

export const ConnectMasterAccount: FC<Props> = ({
  publicId,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const { openPopup, closePopup } = useResponsePopup();
  const {
    assetOnboardingControllerApi,
    masterAccountControllerApi,
    locationControllerApi,
  } = useApi();
  const [createdUser, setCreatedUser] =
    useState<MasterAccountCreateResponseDto | null>(null);
  const [parkIsCreated, setParkIsCreated] = useState(false);
  const [step, setStep] = useState<0 | 1>(0);

  const { isUsernameExist, isParkNameExist } = useUniqueEntityValidators();

  const schema = useMemo(() => {
    return validationSchemas[step](
      t,
      step === 0 ? isUsernameExist : isParkNameExist,
    );
  }, [isParkNameExist, isUsernameExist, step, t]);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    formState: { isDirty },
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
  } = form;

  const existingCustomer = watch('existingCustomer');
  const newCustomer = watch('newCustomer');

  const handleClose = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      setStep(0);
      reset();
      onClose(e);
    },
    [reset, onClose],
  );

  const createMasterAccount = useCallback(
    async (username: string) => {
      if (!username) return;

      const { data } = await masterAccountControllerApi.createMasterAccount({
        masterAccountCreateRequestDto: { name: username },
      });

      setCreatedUser(data);
    },
    [masterAccountControllerApi],
  );

  const formSubmit = async (data: FormValues) => {
    try {
      if (!createdUser && data.newCustomer) {
        await createMasterAccount(data.newCustomer);
      }

      const owner = (data.existingCustomer || data.newCustomer) as string;

      if (!parkIsCreated && data.newPark?.name && data.newPark?.coordinates) {
        const [latitude, longitude] = data.newPark.coordinates
          .replace(/ /g, '')
          ?.split(',')
          .map((coordinateStr) => Number(coordinateStr));

        await locationControllerApi.create({
          locationDto: {
            name: data.newPark?.name,
            latitude,
            longitude,
            customerName: owner,
          },
        });

        setParkIsCreated(true);
      }

      await assetOnboardingControllerApi.addMapping({
        customerMappingDto: [
          {
            publicId,
            owner,
            locationName: data.newPark?.name || (data.existingPark as string),
          },
        ],
      });
      setShowSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;
        toast.error(message);

        if (message.endsWith('already exists.')) {
          if (message.startsWith('Customer')) {
            setStep(0);
            await trigger('newCustomer');
          }

          if (message.startsWith('Location with name')) {
            await trigger('newPark.name');
          }
        }
      }
    }
  };

  const canGoStep2 = useMemo(() => {
    if (existingCustomer || newCustomer) return true;
  }, [existingCustomer, newCustomer]);

  const goStep2 = useCallback(async () => {
    try {
      const isFieldValid = await trigger('newCustomer');
      if (!isFieldValid) return;
      await trigger('existingCustomer');
      setStep(1);
    } catch {}
  }, [trigger]);

  useEffect(() => {
    if (showSuccess) {
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('components.masterAccountConnectionSuccess.title'),
        children: createdUser ? (
          <UserCredentials
            variant="customer"
            username={createdUser.name}
            password={createdUser.password}
          />
        ) : undefined,
        primaryButton: {
          text: t('continueSession'),
          onClick: () => {
            closePopup();
            setShowSuccess(false);
            setCreatedUser(null);
            onSuccess();
          },
        },
      });
    }
  }, [showSuccess, t, openPopup, closePopup, createdUser, onSuccess]);

  return (
    <ConnectMasterAccountDialogWrapper
      open={!!publicId && !showSuccess}
      onClose={handleClose}
    >
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit(formSubmit)}>
          <Box height="100%" display="flex" flexDirection="column">
            <Typography
              variant="subheading"
              sx={{ pb: 1 }}
              color="green.700"
              component="div"
            >
              {t('step')} {`${step + 1}/2`}
            </Typography>
            <Typography variant="h2" sx={{ mb: 5 }} color="black.600">
              {t('components.connectMasterAccount.title')}
            </Typography>

            <CurrentStepWrapper isCurrent={step === 0}>
              <Typography
                variant="bodyM"
                color="black.600"
                component="div"
                sx={{ pb: 3 }}
              >
                {t('components.assetOnboarding.subTitleMasterAccount')}
              </Typography>

              <SelectOrCreateCustomer />

              <ButtonsWrapper
                padding={2}
                display="flex"
                gap={2}
                justifyContent="center"
              >
                <CommonButton
                  variant="outlined"
                  onClick={handleClose}
                  data-testid="close-button"
                >
                  {t('cancel')}
                </CommonButton>
                <CommonButton
                  variant="contained"
                  data-testid="submit"
                  endIcon={<ArrowRight />}
                  disabled={!canGoStep2}
                  onClick={goStep2}
                >
                  {t('next')}
                </CommonButton>
              </ButtonsWrapper>
            </CurrentStepWrapper>

            <CurrentStepWrapper isCurrent={step === 1}>
              <Typography
                variant="bodyM"
                color="black.600"
                component="div"
                sx={{ pb: 3 }}
              >
                {newCustomer
                  ? t('components.assetOnboarding.subTitleCreatePark')
                  : t('components.assetOnboarding.subTitleOnePark')}
              </Typography>

              <SelectOrCreatePark
                selectProps={{
                  name: 'existingPark',
                  defaultValue: '',
                }}
              />

              <ButtonsWrapper display="flex" gap={2} justifyContent="center">
                <CommonButton
                  variant="outlined"
                  onClick={() => {
                    setValue('existingPark', '');
                    setValue('newPark', { name: '', coordinates: '' });
                    clearErrors('existingPark');
                    clearErrors('newPark');
                    setStep(0);
                  }}
                  data-testid="close-button"
                  startIcon={<ArrowLeft />}
                >
                  {t('back')}
                </CommonButton>
                <CommonButton
                  variant="contained"
                  data-testid="submit"
                  type="submit"
                  disabled={!isDirty}
                >
                  {t('buttons.submit')}
                </CommonButton>
              </ButtonsWrapper>
            </CurrentStepWrapper>
          </Box>
        </FormWrapper>
      </FormProvider>
    </ConnectMasterAccountDialogWrapper>
  );
};
