import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';

import {
  IInvestigationContext,
  InvestigationContext,
} from './InvestigationContext';
import {
  fieldsToSend,
  sorting,
} from 'components/pages/protected/Investigation/components/History/constants';
import useApi from 'contexts/api';
import { RestorationRequestControllerApiGetRestorationRequestsRequest } from 'openapi-api/admin-service';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { sortingMapping } from 'utils/functions/sortingMapping';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

const InvestigationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { customerControllerApi, restorationRequestControllerApi } = useApi();

  const getCustomers = useCallback(async () => {
    try {
      return (
        await customerControllerApi.allCustomers({
          status: ['ACTIVE', 'INACTIVE'],
        })
      ).data;
    } catch {
      return [];
    }
  }, [customerControllerApi]);

  const { resource: customers } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomers,
    disableGlobalLoader: true,
  });

  const {
    sortModel,
    onSortModelChange,
    searchParams,
    paginationModel,
    onPaginationModelChange,
  } = useDataGrid();

  const getInvestigationsHistory = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
    const params: RestorationRequestControllerApiGetRestorationRequestsRequest =
      {
        ...objFromQuery,
        size: Number(objFromQuery.size) || 10,
        sort: sortingMapping(sorting, objFromQuery.sort as string | undefined),
      };
    try {
      const data = (
        await restorationRequestControllerApi.getRestorationRequests(params)
      ).data;
      return data;
    } catch {
      return {};
    }
  }, [restorationRequestControllerApi, searchParams]);

  const {
    resource: investigationsHistory,
    isLoading: isHistoryLoading,
    fetch: refreshHistory,
  } = useAsyncResourceWithPulling({
    fetchResource: getInvestigationsHistory,
    pullingInterval: 30,
    defaultValue: {},
  });

  const resetInvestigationDashboard = useCallback(
    async (restorationId: string) => {
      if (!restorationId) return;

      try {
        await restorationRequestControllerApi.resetRestorationRequest({
          restorationId,
        });
      } catch {
        return;
      }
    },
    [restorationRequestControllerApi],
  );

  const contextValue = useMemo(
    (): IInvestigationContext => ({
      customers,
      investigationsHistory,
      isHistoryLoading,
      refreshHistory,
      sortModel,
      onSortModelChange,
      paginationModel,
      onPaginationModelChange,
      resetInvestigationDashboard,
    }),
    [
      customers,
      investigationsHistory,
      isHistoryLoading,
      onPaginationModelChange,
      onSortModelChange,
      paginationModel,
      refreshHistory,
      resetInvestigationDashboard,
      sortModel,
    ],
  );

  return (
    <InvestigationContext.Provider value={contextValue}>
      {children}
    </InvestigationContext.Provider>
  );
};

export default InvestigationProvider;
