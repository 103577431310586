import { FC } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import { UserChangedWindTurbineNameDto } from 'openapi-api/admin-service';

export const WindTurbineDetailsChangedTemplate: FC<
  UserChangedWindTurbineNameDto
> = ({
  subjectUser,
  previousAssetNameForAdmin,
  currentAssetNameForAdmin,
  eventDateTime,
}) => {
  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.windTurbineDetailsChanged"
        values={{ eventDateTime }}
        components={{
          userLink: (
            <UserIdLink
              to={
                routePaths.users.adminList.profile(subjectUser?.id!).historyLog
              }
            >
              {subjectUser?.userId}
            </UserIdLink>
          ),
        }}
      />
      <br />
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.windTurbineNameChanged"
        values={{
          previous: previousAssetNameForAdmin,
          current: currentAssetNameForAdmin,
        }}
        components={{ bold: <strong /> }}
      />
    </>
  );
};
