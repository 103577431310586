import { Link, SxProps, Theme, useMediaQuery } from '@mui/material';
import { FC, useMemo } from 'react';

import useNotificationsContext from 'contexts/notifications';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';

interface Props {
  publicId: string;
  locationId?: number | string;
  link?: string;
  linkText?: string;
  newTab?: boolean;
  sx?: SxProps;
  className?: string;
}

export const WTLink: FC<Props> = ({
  link,
  locationId,
  publicId,
  linkText,
  newTab,
  sx,
  className,
}) => {
  const isMobileOrTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('desktop'),
  );
  const { closeNotificationsPopup } = useNotificationsContext();

  const target = useMemo(() => {
    if (newTab) return '_blank';
  }, [newTab]);

  const onLinkClick = useMemo(() => {
    if (!newTab && isMobileOrTablet) return closeNotificationsPopup;
  }, [newTab, isMobileOrTablet, closeNotificationsPopup]);

  return (
    <Link
      href={
        link ||
        getWindTurbinePageLink({
          assetLocationId: locationId,
          assetPublicId: publicId,
        }).root
      }
      underline="none"
      fontWeight="700"
      target={target}
      onClick={onLinkClick}
      sx={sx}
      className={className}
    >
      {linkText || publicId}
    </Link>
  );
};
