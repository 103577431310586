import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WTLinkStyled } from 'components/shared/HistoryLog/styles';
import {
  UserChangedAssetOperationalStatusDto,
  UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const OperationalStatusChangedTemplate: FC<
  UserChangedAssetOperationalStatusDto & { locationId?: number }
> = ({
  operationalStatus,
  eventDateTime,
  locationId,
  publicId,
  assetNameForAdmin,
  subjectUser,
}) => {
  const { t } = useTranslation();

  const operationalStatusText = useMemo(
    () =>
      operationalStatus ===
      UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum.ACTIVATED
        ? t('pages.turbine.on')
        : t('pages.turbine.off'),
    [operationalStatus, t],
  );

  const portalText = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN
        ? t('adminPortal')
        : t('customerPortal'),
    [t, subjectUser],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.user.activities.operationalStatusChanged"
      values={{
        status: operationalStatusText.toUpperCase(),
        userId: subjectUser?.userId,
        portal: portalText,
        eventDateTime,
      }}
      components={{
        assetLink: (
          <WTLinkStyled
            locationId={locationId}
            publicId={String(publicId!)}
            linkText={assetNameForAdmin}
          />
        ),
        bold: <strong />,
      }}
    />
  );
};
