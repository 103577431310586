import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { adminUsersFilterValues, fieldsToSend } from './constants';
import { AddUserButton, TableHeadWrapper } from '../../styles';
import { AddAdminUserPopup } from '../AddAdminUserPopup';
import { UsersTable } from '../UsersTable';
import { FilterMenu, Search } from 'components/common/Table/CommonTable';
import { Plus } from 'components/icons';
import { FilterItem } from 'types/general';
import useDataGrid from 'utils/hooks/useDataGrid';
import { usePopup } from 'utils/hooks/usePopup';

export const AdminUsers: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { t } = useTranslation();
  const { searchParams, onSearch, onFilterModelChange } = useDataGrid();

  const [isAddUserPopupOpened, openAddUserPopup, closeAddUserPopup] =
    usePopup();

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      status: { value: searchParams.get('status') },
      userRole: { value: searchParams.get('userRole') },
    }),
    [searchParams],
  );

  const filterValues = useMemo(() => adminUsersFilterValues(t), [t]);

  return (
    <Box>
      <TableHeadWrapper>
        <Search onSearch={onSearch} light sx={{ width: '100%' }} />
        <Box display="flex" gap={2} justifyContent="flex-end">
          <FilterMenu
            checkedFilterValues={checkedFilterValues}
            filterValues={filterValues}
            onFilterModelChange={onFilterModelChange}
            light
            sx={{ ml: 'auto' }}
          />
          <AddUserButton
            onClick={openAddUserPopup}
            endIcon={<Plus />}
            data-testid="add-user-button"
          >
            {isDesktop ? t('pages.users.adminUsers.addUserButtonText') : null}
          </AddUserButton>
        </Box>
      </TableHeadWrapper>
      <UsersTable
        variant="admin"
        fieldsToSend={fieldsToSend}
        checkedFilterValues={checkedFilterValues}
        filterValues={filterValues}
      />
      {isAddUserPopupOpened && (
        <AddAdminUserPopup isOpen onClose={closeAddUserPopup} />
      )}
    </Box>
  );
};
