import { Timeframes } from 'components/common/DatePicker';
import { SelectItem, TranslationFunction } from 'types/general';

export const getTimeframeSelectItems = (
  t: TranslationFunction,
): SelectItem[] => {
  return [
    {
      label: t('timeframes.today'),
      value: Timeframes.today,
    },
    {
      label: t('timeframes.yesterday'),
      value: Timeframes.yesterday,
    },
    {
      label: t('timeframes.lastWeek'),
      value: Timeframes.lastWeek,
    },
    {
      label: t('timeframes.lastMonth'),
      value: Timeframes.lastMonth,
    },
  ];
};
