import { fieldsToSend } from './constants';
import {
  ParkAssetsStatuses,
  ParksSortingOrder,
  ParksSortingValues,
} from './types';
import { LocationControllerApiGetLocationsRequest } from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';

export const getRequestParams = (
  searchParams: URLSearchParams,
): LocationControllerApiGetLocationsRequest => {
  const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);

  return {
    page: +(objFromQuery.page ?? 0),
    size: +(objFromQuery.size ?? 10),
    status: objFromQuery.status as ParkAssetsStatuses,
    customerId: objFromQuery.customerId
      ? Number(objFromQuery.customerId)
      : undefined,
    search: (objFromQuery.search as string) || undefined,
    sort: objFromQuery.sort
      ? ((objFromQuery.sort as string).split(',')[0] as ParksSortingValues)
      : undefined,
    sortOrder: objFromQuery.sort
      ? ((objFromQuery.sort as string).split(',')[1] as ParksSortingOrder)
      : undefined,
  };
};

export const getSortingItems = (t: TranslationFunction) => [
  {
    label: t('pages.parks.sorting.lastAdded'),
    value: `${ParksSortingValues.addedDatetime},${ParksSortingOrder.desc}`,
  },
  {
    label: t('pages.parks.sorting.firstAdded'),
    value: `${ParksSortingValues.addedDatetime},${ParksSortingOrder.asc}`,
  },
  {
    label: t('pages.parks.sorting.highActiveEnergy'),
    value: `${ParksSortingValues.activeEnergy},${ParksSortingOrder.desc}`,
  },
  {
    label: t('pages.parks.sorting.lowActiveEnergy'),
    value: `${ParksSortingValues.activeEnergy},${ParksSortingOrder.asc}`,
  },
  {
    label: t('pages.parks.sorting.highLostEnergy'),
    value: `${ParksSortingValues.lostEnergy},${ParksSortingOrder.desc}`,
  },
  {
    label: t('pages.parks.sorting.lowLostEnergy'),
    value: `${ParksSortingValues.lostEnergy},${ParksSortingOrder.asc}`,
  },
];
