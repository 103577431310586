import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  IMasterAccountContext,
  MasterAccountContext,
} from './MasterAccountContext';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const MasterAccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId } = useParams();
  const { masterAccountControllerApi } = useApi();

  const getMasterAccountDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await masterAccountControllerApi.getMasterAccountDetailsById({
          id: Number(userId),
        })
      ).data;
    } catch {}
  }, [userId, masterAccountControllerApi]);

  const {
    resource: masterAccount,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getMasterAccountDetails,
  });

  const contextValue = useMemo(
    (): IMasterAccountContext => ({
      masterAccount,
      isLoading,
      fetch,
    }),
    [masterAccount, fetch, isLoading],
  );

  return (
    <MasterAccountContext.Provider value={contextValue}>
      {children}
    </MasterAccountContext.Provider>
  );
};
