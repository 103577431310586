import dayjs, { Dayjs } from 'dayjs';

import { Timeframes } from './constants';

export const getTimeframePeriod = (
  value?: string,
): { from: Dayjs; to: Dayjs } | undefined => {
  if (!value) return;

  const currentDatetime = dayjs();

  if (value === Timeframes.today) {
    return {
      from: currentDatetime.startOf('day'),
      to: currentDatetime,
    };
  }

  if (value === Timeframes.yesterday) {
    return {
      from: currentDatetime.subtract(1, 'day').startOf('day'),
      to: currentDatetime.subtract(1, 'day').endOf('day'),
    };
  }

  if (value === Timeframes.lastWeek) {
    return {
      from: currentDatetime.subtract(1, 'week').startOf('week'),
      to: currentDatetime.subtract(1, 'week').endOf('week'),
    };
  }

  if (value === Timeframes.lastMonth) {
    return {
      from: currentDatetime.subtract(1, 'month').startOf('month'),
      to: currentDatetime.subtract(1, 'month').endOf('month'),
    };
  }
};

export const dateStringToObject = (date?: string) => {
  if (date?.includes(',')) {
    const [from, to] = date.split(',');
    return { from, to };
  }
  const dayjsPeriod = getTimeframePeriod(date);
  return {
    from: dayjsPeriod?.from.toISOString(),
    to: dayjsPeriod?.to.toISOString(),
  };
};
