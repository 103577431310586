import { FC } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import { UserInitiatedWindTurbineOnboardingDto } from 'openapi-api/admin-service';

export const OnboardingInitiatedTemplate: FC<
  UserInitiatedWindTurbineOnboardingDto
> = ({ subjectUser, assetNameForAdmin, eventDateTime }) => {
  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.onboardingInitiated"
      values={{ assetName: assetNameForAdmin, eventDateTime }}
      components={{
        userLink: (
          <UserIdLink
            to={routePaths.users.adminList.profile(subjectUser?.id!).historyLog}
          >
            {subjectUser?.userId}
          </UserIdLink>
        ),
      }}
    />
  );
};
