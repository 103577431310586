import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { IAdminUserContext, AdminUserContext } from './AdminUserContext';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const AdminUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId } = useParams();
  const { adminUserControllerApi } = useApi();

  const getAdminUserDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await adminUserControllerApi.getAdminUserDetailsById({
          id: Number(userId),
        })
      ).data;
    } catch {}
  }, [userId, adminUserControllerApi]);

  const {
    resource: adminUser,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getAdminUserDetails,
  });

  const contextValue = useMemo(
    (): IAdminUserContext => ({
      adminUser,
      isLoading,
      fetch,
    }),
    [adminUser, fetch, isLoading],
  );

  return (
    <AdminUserContext.Provider value={contextValue}>
      {children}
    </AdminUserContext.Provider>
  );
};
