import { BreadcrumbItem } from 'components/common/Breadcrumbs/types';
import routePaths from 'constants/routePaths';
import { WindTurbineDto } from 'openapi-api/admin-service';
import { FilterTypes, FilterValues, TranslationFunction } from 'types/general';

export const getBreadcrumbItems = (
  windTurbine: WindTurbineDto | undefined,
  t: TranslationFunction,
): BreadcrumbItem[] => {
  const locationId = windTurbine?.location?.id;
  const wtPublicId = windTurbine?.publicId || '';
  const assignedToPark = typeof locationId !== 'undefined';
  const wtPath = assignedToPark
    ? routePaths.parks.specific(locationId).windTurbine(wtPublicId)
    : routePaths.parks.unassigned().windTurbine(wtPublicId);

  return [
    {
      label: t('pages.parks.title'),
      path: routePaths.parks.root,
    },
    ...(assignedToPark
      ? [
          {
            label: windTurbine?.location?.name || '',
            path: routePaths.parks.specific(locationId).root,
          },
        ]
      : [
          {
            label: t('pages.parks.unassignedTurbines.title'),
            path: routePaths.parks.unassigned().root,
          },
        ]),
    {
      label: wtPublicId,
      path: wtPath.root,
    },
    {
      label: t('components.historyLog.title'),
      path: wtPath.historyLog.root,
    },
  ];
};

export const getSortingItems = (t: TranslationFunction) => [
  {
    label: t('pages.turbine.historyLog.lastAdded'),
    value: 'create_datetime,DESC',
  },
  {
    label: t('pages.turbine.historyLog.firstAdded'),
    value: 'create_datetime,ASC',
  },
];

export const getFilterValues = (t: TranslationFunction): FilterValues => ({
  dateRange: {
    label: t('components.filterMenu.filterTimePeriod'),
    type: FilterTypes.dates,
    values: [],
  },
});

export const getFilterByTypeValue = (pathname: string) => {
  if (pathname.includes('/all')) {
    return undefined;
  }

  if (pathname.includes('/activities')) {
    return 'USER_ACTIVITIES';
  }

  if (pathname.includes('/statuses-and-errors')) {
    return 'STATUSES_AND_ERRORS';
  }
};
