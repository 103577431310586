import { Box, ChipProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIdBlock } from './styles';
import { Copy, CopySuccess } from 'components/icons';
import { useCopyToClipboard } from 'utils/hooks/useCopyToClipboard';

interface IIdBlockProps extends ChipProps {
  id?: string;
}

export const IdBlock: FC<IIdBlockProps> = ({ id, ...rest }) => {
  const { copied, copyText } = useCopyToClipboard(id || '');
  const { t } = useTranslation();

  return (
    <>
      {!copied && (
        <StyledIdBlock
          label={
            <Box display="flex" alignContent="center" gap={1.25}>
              <Typography
                variant="bodySStrong"
                textTransform="none"
              >{`MAC: ${id}`}</Typography>
              <Copy width={20} height={20} />
            </Box>
          }
          onClick={copyText}
          {...rest}
        />
      )}
      {copied && (
        <StyledIdBlock
          label={
            <Box display="flex" alignContent="center" gap={1.25}>
              <Typography variant="bodySStrong" textTransform="none">
                {t('copied')}
              </Typography>
              <CopySuccess width={20} height={20} />
            </Box>
          }
          sx={{ width: '212px' }}
        />
      )}
    </>
  );
};
