import { FC } from 'react';
import { useOutlet } from 'react-router-dom';

import { MasterAccountProvider } from 'contexts/masterAccount';

export const MasterAccountContainer: FC = () => {
  const outlet = useOutlet();

  return <MasterAccountProvider>{outlet}</MasterAccountProvider>;
};
