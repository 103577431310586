import { createContext } from 'react';

import { IApiContext } from './types';
import {
  CommandControllerApi,
  AssetControllerApi,
  JwtAuthControllerApi,
  WindTurbineControllerApi,
  CustomerControllerApi,
  AssetOnboardingControllerApi,
  Configuration as ApiConfiguration,
  NotificationControllerApi,
  UserDetailsControllerApi,
  LocationControllerApi,
  RestorationRequestControllerApi,
  VideoStreamControllerApi,
  AdminUserControllerApi,
  EmailVerificationControllerApi,
  MasterAccountControllerApi,
  HistoryLogControllerApi,
} from 'openapi-api/admin-service';
import {
  ReportRequestControllerApi,
  Configuration as ReportingConfiguration,
} from 'openapi-api/report-service';

export const api: IApiContext = {
  commandControllerApi: new CommandControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  assetControllerApi: new AssetControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  jwtAuthControllerApi: new JwtAuthControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  windTurbineControllerApi: new WindTurbineControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  customerControllerApi: new CustomerControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  assetOnboardingControllerApi: new AssetOnboardingControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  notificationControllerApi: new NotificationControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  userDetailsControllerApi: new UserDetailsControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  reportRequestControllerApi: new ReportRequestControllerApi(
    new ReportingConfiguration({ basePath: process.env.REACT_APP_REPORTS }),
  ),
  locationControllerApi: new LocationControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  restorationRequestControllerApi: new RestorationRequestControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  videoStreamControllerApi: new VideoStreamControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  adminUserControllerApi: new AdminUserControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  emailVerificationControllerApi: new EmailVerificationControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  masterAccountControllerApi: new MasterAccountControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
  historyLogControllerApi: new HistoryLogControllerApi(
    new ApiConfiguration({ basePath: process.env.REACT_APP_API }),
  ),
};

const Context = createContext<IApiContext>(api);

export default Context;
