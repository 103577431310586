import axios, { AxiosError, isAxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { isForbiddenError, isUnauthorizedError } from './helpers';
import routePaths from '../../constants/routePaths';
import useApi from '../api';
import { JwtAuthentication } from 'openapi-api/admin-service';

type RefreshFunction = (refreshToken: string) => Promise<JwtAuthentication>;

const useInterceptors = (
  onLogout: (autoLogout?: boolean) => void,
  accessData: JwtAuthentication | null,
  setAccessData: (accessData: JwtAuthentication | null) => void,
  handleForbiddenError: (error: AxiosError<{ message?: string }>) => void,
) => {
  const refreshingFunc = useRef<Promise<JwtAuthentication> | undefined>(
    undefined,
  );
  const { jwtAuthControllerApi } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const doTokenRefresh: RefreshFunction = async (refreshToken) => {
      const { data } = await jwtAuthControllerApi.refreshToken(
        { refreshToken },
        {
          headers: {
            isRefreshCall: true,
          },
        },
      );

      return data;
    };

    const responseIntercept = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalConfig = error.config;

        if (isForbiddenError(error)) {
          handleForbiddenError(error);
        }

        if (!accessData?.refreshToken || !isUnauthorizedError(error)) {
          return Promise.reject(error);
        }

        if (error.config?.headers?.isRefreshCall) {
          navigate(routePaths.login);
          setAccessData({});
          return Promise.reject(error);
        }

        try {
          if (!refreshingFunc.current)
            refreshingFunc.current = doTokenRefresh(accessData.refreshToken);

          const newToken = await refreshingFunc.current;

          setAccessData(newToken);

          originalConfig.headers.Authorization = `Bearer ${newToken.accessToken}`;

          try {
            return await axios.request(originalConfig);
          } catch (innerError) {
            if (isAxiosError(innerError) && isUnauthorizedError(innerError)) {
              throw innerError;
            }
          }
        } catch (err) {
          await Promise.reject(err);
        } finally {
          refreshingFunc.current = undefined;
        }
      },
    );

    return () => {
      axios.interceptors.response.eject(responseIntercept);
    };
  }, [
    onLogout,
    accessData,
    jwtAuthControllerApi,
    setAccessData,
    navigate,
    handleForbiddenError,
  ]);
};

export default useInterceptors;
