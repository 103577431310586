import { useContext } from 'react';

import { MasterAccountContext } from './MasterAccountContext';
import { MasterAccountProvider } from './MasterAccountProvider';

const useMasterAccountContext = () => {
  const context = useContext(MasterAccountContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useMasterAccountContext must be used within a MasterAccountProvider',
    );
  }

  return context;
};

export { useMasterAccountContext, MasterAccountProvider };
