import { FC } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CommonButton from '../CommonButton';
import { useCopyToClipboard } from 'utils/hooks/useCopyToClipboard';

interface ICopyButtonProps {
  text?: string;
  light?: boolean;
}

export const CopyButton: FC<ICopyButtonProps> = ({ text = '', light }) => {
  const { copied, copyText } = useCopyToClipboard(text);
  const { t } = useTranslation();

  return (
    <>
      {!copied && (
        <CommonButton
          variant="outlined"
          color={light ? 'white' : undefined}
          onClick={copyText}
          size="medium"
          sx={{
            width: '180px',
            backgroundColor: 'transparent',
          }}
        >
          {t('copyCreds')}
        </CommonButton>
      )}
      {copied && (
        <CommonButton
          variant="contained"
          size="medium"
          sx={{ width: '180px', my: 0.25 }}
        >
          {t('copied')}
        </CommonButton>
      )}
    </>
  );
};
