/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AssetDetailsDto
 */
export interface AssetDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AssetDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetDetailsDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDetailsDto
     */
    'turbineName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDetailsDto
     */
    'locationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDetailsDto
     */
    'status'?: AssetDetailsDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssetDetailsDtoStatusEnum {
    NOT_SET = 'NOT_SET',
    CREATED = 'CREATED',
    ONBOARDING = 'ONBOARDING',
    OFFLINE = 'OFFLINE',
    READY = 'READY',
    ERROR = 'ERROR',
    DELETED = 'DELETED'
}


