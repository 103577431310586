import { Typography, Box, ThemeProvider } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { defaultValues } from './constants';
import {
  FormWrapper,
  FormStyled,
  FormButtons,
  OrWrapper,
  ClearButton,
  FieldsWrapperGeneral,
  FieldsWrapperTime,
  DateRangePickerStyled,
} from './styles';
import { CreateReportFormValues } from './types';
import CommonButton from 'components/common/CommonButton';
import { getTimeframePeriod } from 'components/common/DatePicker';
import { ValueProps } from 'components/common/DatePicker/DateRangePicker/DateRangePicker';
import { FormSelect } from 'components/common/form/Select';
import { getTimeframeSelectItems } from 'components/common/Table/CommonTable/components/FilterMenu/helpers';
import useApi from 'contexts/api';
import useReport from 'contexts/report';
import { AssetDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { darkTheme } from 'styles/theme';
import { SelectItem } from 'types/general';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const CreateReportForm: FC = () => {
  const { t } = useTranslation();
  const { customerControllerApi, locationControllerApi } = useApi();
  const { setReportValues } = useReport();

  const getCustomers = useCallback(async () => {
    try {
      return (
        await customerControllerApi.allCustomers({
          status: ['ACTIVE', 'INACTIVE'],
        })
      ).data;
    } catch {
      return [];
    }
  }, [customerControllerApi]);

  const { resource: customers } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomers,
    disableGlobalLoader: true,
  });

  const customerSelectItems = useMemo(
    (): SelectItem[] =>
      customers.map((customer) => ({
        label: String(customer.name),
        value: String(customer.id),
      })),
    [customers],
  );

  const form = useForm<CreateReportFormValues>({ defaultValues });
  const { watch, reset, setValue, resetField, formState } = form;
  const customerId = watch('customerId');
  const locationNames = watch('locationNames');
  const assetIds = watch('assetIds');
  const reportPeriod = watch('reportPeriod');
  const reportFromDate = watch('reportFromDate');
  const reportToDate = watch('reportToDate');

  const timeframeSelectItems = useMemo(() => getTimeframeSelectItems(t), [t]);

  const getCustomerLocations = useCallback(async () => {
    const customerName = customers.find(
      (customer) => String(customer?.id) === customerId,
    )?.name;

    if (typeof customerName === 'undefined') return [];

    try {
      return (await locationControllerApi.getAllForCustomer({ customerName }))
        .data;
    } catch {
      return [];
    }
  }, [customerId, customers, locationControllerApi]);

  const { resource: customerLocations } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomerLocations,
    disableGlobalLoader: true,
  });

  const customerLocationSelectItems = useMemo(
    () =>
      customerLocations.map(
        (location): SelectItem => ({
          label: location.name,
          value: location.name,
        }),
      ),
    [customerLocations],
  );

  const getCustomerAssets = useCallback(async () => {
    if (typeof customerId === 'undefined' || locationNames.length === 0)
      return [];

    try {
      return (
        await customerControllerApi.getCustomerAssetDetails({
          customerId: Number(customerId),
        })
      ).data.filter(
        (asset) =>
          asset.locationName && locationNames.includes(asset.locationName),
      );
    } catch {
      return [];
    }
  }, [customerId, locationNames, customerControllerApi]);

  const { resource: customerAssets } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomerAssets,
    disableGlobalLoader: true,
  });

  const customerAssetSelectItems = useMemo(
    (): SelectItem[] =>
      customerAssets
        .filter(
          (asset) =>
            asset.status !== AssetDetailsDtoStatusEnum.CREATED &&
            asset.status !== AssetDetailsDtoStatusEnum.ONBOARDING,
        )
        .map(
          (asset): SelectItem => ({
            label: String(asset.turbineName),
            value: String(asset.publicId),
          }),
        ),
    [customerAssets],
  );

  const isSubmitButtonDisabled = useMemo(() => {
    if (!customerId || !assetIds?.length) {
      return true;
    }

    if (!reportPeriod && !(reportFromDate && reportToDate)) {
      return true;
    }

    return false;
  }, [customerId, assetIds, reportPeriod, reportFromDate, reportToDate]);

  const onResetButtonClick = useCallback(() => {
    reset(defaultValues);
    setReportValues(undefined);
  }, [reset, setReportValues]);

  const handleDateRangeChange = useCallback(
    (fieldName: ValueProps, value: Dayjs | null) => {
      resetField('reportPeriod');

      if (fieldName === 'fromDate') {
        const dateValue = value ? value.startOf('day').toISOString() : '';
        setValue('reportFromDate', dateValue);
        return;
      }

      if (fieldName === 'toDate') {
        let dateValue = '';
        if (value) {
          dateValue = value.isSame(dayjs(), 'day')
            ? dayjs().toISOString()
            : value.endOf('day').toISOString();
        }
        setValue('reportToDate', dateValue);
      }
    },
    [setValue, resetField],
  );

  const onSubmit = form.handleSubmit((data: CreateReportFormValues) => {
    const customer = customers.find(({ id }) => String(id) === data.customerId);
    const locations = customerLocations.filter((location) =>
      locationNames.includes(location.name),
    );
    const assets = customerAssets.filter(
      (asset) => asset?.publicId && data.assetIds?.includes(asset.publicId),
    );
    const from =
      getTimeframePeriod(data.reportPeriod)?.from.toISOString() ||
      data.reportFromDate;
    const to =
      getTimeframePeriod(data.reportPeriod)?.to.toISOString() ||
      data.reportToDate;

    if (!customer || !data.assetIds?.length || !from || !to) return;

    setReportValues({
      customer,
      locations,
      assets,
      period: {
        from,
        to,
      },
    });
  });

  useEffect(() => {
    setValue('locationNames', []);
  }, [customerId, setValue]);

  useEffect(() => {
    setValue('assetIds', []);
  }, [locationNames, setValue]);

  return (
    <ThemeProvider theme={darkTheme}>
      <FormWrapper>
        <FormProvider {...form}>
          <FormStyled onSubmit={onSubmit}>
            <Typography variant="h3" color="white.main">
              {t('pages.reports.form.title')}
            </Typography>
            <Box>
              <Typography variant="subheading" color="white.main">
                {t('pages.reports.form.generalInfoSubtitle')}
              </Typography>
              <FieldsWrapperGeneral sx={{ mt: 2.5 }}>
                <FormSelect
                  label={t('masterAccount')}
                  name="customerId"
                  items={customerSelectItems}
                  fullWidth
                />
                <FormSelect
                  label={t('pages.reports.form.locationName')}
                  name="locationNames"
                  items={customerLocationSelectItems}
                  disabled={!customerId}
                  multiple
                  fullWidth
                />
                <FormSelect
                  label={t('pages.reports.form.windTurbineName')}
                  name="assetIds"
                  items={customerAssetSelectItems}
                  disabled={!locationNames.length}
                  multiple
                  fullWidth
                />
              </FieldsWrapperGeneral>
            </Box>
            <Box>
              <Typography variant="subheading" color="white.main">
                {t('pages.reports.form.timePeriodSubtitle')}
              </Typography>
              <FieldsWrapperTime sx={{ mt: 2.5 }}>
                <FormSelect
                  label={t('pages.reports.form.reportPeriod')}
                  name="reportPeriod"
                  items={timeframeSelectItems}
                  onItemClick={() => {
                    setValue('reportFromDate', '');
                    setValue('reportToDate', '');
                  }}
                  fullWidth
                />
                <OrWrapper>
                  <Typography variant="bodyM">{t('or')}</Typography>
                </OrWrapper>
                <DateRangePickerStyled
                  onChange={handleDateRangeChange}
                  fromDate={reportFromDate}
                  toDate={reportToDate}
                />
              </FieldsWrapperTime>
            </Box>
            <FormButtons>
              <ClearButton
                variant="text"
                size="text"
                onClick={onResetButtonClick}
                disabled={!formState.isDirty}
              >
                {t('pages.reports.form.resetButton')}
              </ClearButton>
              <CommonButton type="submit" disabled={isSubmitButtonDisabled}>
                {t('pages.reports.form.submitButton')}
              </CommonButton>
            </FormButtons>
          </FormStyled>
        </FormProvider>
      </FormWrapper>
    </ThemeProvider>
  );
};
