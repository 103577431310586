import { FC } from 'react';
import { Trans } from 'react-i18next';

import { ParkLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import { UserChangedParkDetailsDto } from 'openapi-api/admin-service';

export const ParkDetailsChangedTemplate: FC<UserChangedParkDetailsDto> = ({
  eventDateTime,
  locationId,
  previousParkName,
  previousParkLatitudeLongitude,
  currentParkName,
  currentParkLatitudeLongitude,
  subjectUser,
}) => {
  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.user.activities.parkDetailsChanged"
        values={{ userId: subjectUser?.userId, eventDateTime }}
        components={{
          parkLink: (
            <ParkLink to={routePaths.parks.specific(locationId!).root}>
              {currentParkName}
            </ParkLink>
          ),
          bold: <strong />,
        }}
      />
      {previousParkName && (
        <>
          <br />
          <Trans
            i18nKey="components.historyLog.templates.user.activities.parkNameChanged"
            values={{ previous: previousParkName, current: currentParkName }}
            components={{ bold: <strong /> }}
          />
        </>
      )}
      {previousParkLatitudeLongitude && (
        <>
          <br />
          <Trans
            i18nKey="components.historyLog.templates.user.activities.parkCoordinatesChanged"
            values={{
              previous: previousParkLatitudeLongitude,
              current: currentParkLatitudeLongitude,
            }}
            components={{ bold: <strong /> }}
          />
        </>
      )}
    </>
  );
};
