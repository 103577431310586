import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';

import { FormValues } from './types';
import { validationSchema } from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import { FormSelect } from 'components/common/form/Select';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import { IPopupProps } from 'components/common/Popup/types';
import { useAdminUserContext } from 'contexts/adminUser';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import { UserUpdateDtoUserRoleEnum } from 'openapi-api/admin-service';
import { SelectItem } from 'types/general';
import { getAdminUserRoleSelectItems } from 'utils/functions/getAdminUserRoleSelectItems';

interface Props extends IPopupProps {}

export const EditAdminUserPopup: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { adminUserControllerApi } = useApi();
  const { adminUser } = useAdminUserContext();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues: {
      username: adminUser?.username || '',
      email: adminUser?.email || '',
      userRole: adminUser?.userRole || '',
    },
  });

  const { openPopup, closePopup } = useResponsePopup();

  const {
    handleSubmit,
    reset,
    register,
    formState: { dirtyFields, isDirty, isSubmitSuccessful },
    setError,
  } = form;

  const userRoleItems = useMemo(
    (): SelectItem[] => getAdminUserRoleSelectItems(t),
    [t],
  );

  const isSubmitButtonDisabled = useMemo(() => !isDirty, [isDirty]);

  const onSubmit = handleSubmit(async (values: FormValues) => {
    if (typeof adminUser?.id === 'undefined') return;

    try {
      await adminUserControllerApi.updateAdminUserDetails({
        id: adminUser.id,
        userUpdateDto: {
          email: dirtyFields.email ? values.email : undefined,
          userRole: values.userRole as UserUpdateDtoUserRoleEnum,
        },
      });

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.adminUsers.editUserPopup.successTitle'),
        subtitle:
          dirtyFields.email && values.email
            ? t(
                'pages.users.adminUsers.editUserPopup.successSubtitleEmailUpdated',
              )
            : undefined,
        primaryButton: {
          text: t('continueSession'),
          onClick: closePopup,
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.data.message) {
        const message: string = e.response.data.message;

        if (message === `Email ${values.email} already exists.`) {
          setError('email', { message: t('form.errors.uniqueEmail') });
          return;
        }

        if (
          message === 'Email address can be updated only once per one hour.'
        ) {
          setError('email', { message: t('form.errors.emailUpdateLimit') });
          return;
        }
      }
    }
  });

  const handlePopupClose = useCallback(() => {
    reset({ username: '', email: '', userRole: '' });
    onClose();
  }, [reset, onClose]);

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    handlePopupClose();
  }, [isSubmitSuccessful, handlePopupClose]);

  return (
    <Popup isOpen={isOpen} onClose={handlePopupClose}>
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography variant="h2" textAlign="left" sx={{ mb: 4 }}>
                {t('pages.users.adminUsers.editUserPopup.title')}
              </Typography>
              <Typography
                variant="bodyM"
                textAlign="left"
                sx={{ display: 'block', mb: 3 }}
              >
                {t('pages.users.adminUsers.editUserPopup.subtitle')}
              </Typography>
              <Box display="flex" flexDirection="column" gap={4}>
                <TextField
                  label={t('username')}
                  fullWidth
                  {...register('username')}
                  testId="username"
                  disabled
                />
                <TextField
                  label={t('emailAddress')}
                  fullWidth
                  {...register('email')}
                  testId="email"
                />
                <FormSelect
                  name="userRole"
                  label={t('role')}
                  items={userRoleItems}
                  fullWidth
                />
              </Box>
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={isSubmitButtonDisabled}
                type="submit"
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.save')}
              </CommonButton>
              <CommonButton variant="outlined" onClick={handlePopupClose}>
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
