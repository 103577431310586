import { styled } from '@mui/material';

import { TablePagination } from 'components/common/Table/CommonTable';

export const HistoryLogPagination = styled(TablePagination)(({ theme }) => ({
  width: 'auto',
  margin: theme.spacing(0, 3),

  [theme.breakpoints.down('desktop')]: {
    '& .MuiTablePagination-input': { width: 66 },
  },
}));
