import { styled } from '@mui/material';

export const GridControlsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.spacing(2.5),
  },

  [theme.breakpoints.down('tablet')]: {
    flexWrap: 'wrap',
  },
}));
