import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbItem } from 'components/common/Breadcrumbs';
import { UserHistoryLog } from 'components/shared/UserHistoryLog';
import routePaths from 'constants/routePaths';
import { useAdminUserContext } from 'contexts/adminUser';

export const AdminUserHistoryLog: FC = () => {
  const { t } = useTranslation();

  const { adminUser } = useAdminUserContext();

  const breadcrumbs = useMemo(
    (): BreadcrumbItem[] => [
      {
        label: t('pages.users.title'),
        path: routePaths.users.adminList.root,
      },
      {
        label: adminUser?.username || '',
        path: routePaths.users.adminList.profile(adminUser?.id!).root,
      },
      {
        label: t('components.historyLog.title'),
        path: routePaths.users.adminList.profile(adminUser?.id!).historyLog,
      },
    ],
    [adminUser, t],
  );

  return <UserHistoryLog userDetails={adminUser} breadcrumbs={breadcrumbs} />;
};
