import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserConnectedToWindTurbineDto,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const UserConnectedTemplate: FC<UserConnectedToWindTurbineDto> = ({
  assetNameForAdmin,
  eventDateTime,
  objectUser,
  subjectUser,
}) => {
  const connectedByAdmin = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN,
    [subjectUser],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.userConnected"
      values={{ assetName: assetNameForAdmin, eventDateTime }}
      components={{
        connectedUserLink: (
          <UserIdLink
            to={
              routePaths.users.customerList.profile(objectUser?.id!).historyLog
            }
          >
            {objectUser?.userId}
          </UserIdLink>
        ),
        connectedByUserLink: connectedByAdmin ? (
          <UserIdLink
            to={routePaths.users.adminList.profile(subjectUser?.id!).historyLog}
          >
            {subjectUser?.userId}
          </UserIdLink>
        ) : (
          <UserIdLink
            to={
              routePaths.users.customerList.profile(subjectUser?.id!).historyLog
            }
          >
            {subjectUser?.userId}
          </UserIdLink>
        ),
      }}
    />
  );
};
