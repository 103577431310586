import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const MasterAccountLink = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.black[900],

  '&:hover': {
    color: theme.palette.green[600],
    '& > .MuiTypography-root': {
      color: theme.palette.green[600],
    },
  },
}));
