import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import {
  UserDeletedWindTurbineDto,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const WindTurbineDeletedTemplate: FC<UserDeletedWindTurbineDto> = ({
  subjectUser,
  assetNameForAdmin,
  assetNameForUser,
  eventDateTime,
}) => {
  const assetName = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN
        ? assetNameForAdmin
        : assetNameForUser,
    [subjectUser, assetNameForAdmin, assetNameForUser],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.user.activities.windTurbineDeleted"
      values={{ userId: subjectUser?.userId, assetName, eventDateTime }}
      components={{
        bold: <strong />,
      }}
    />
  );
};
