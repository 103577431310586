import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserChangedParkDetailsDto,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const ParkDetailsChangedTemplate: FC<UserChangedParkDetailsDto> = ({
  eventDateTime,
  subjectUser,
  previousParkName,
  currentParkName,
  previousParkLatitudeLongitude,
  currentParkLatitudeLongitude,
}) => {
  const changedByAdmin = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN,
    [subjectUser],
  );

  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.asset.activities.parkDetailsChanged"
        values={{ eventDateTime }}
        components={{
          userLink: changedByAdmin ? (
            <UserIdLink
              to={
                routePaths.users.adminList.profile(subjectUser?.id!).historyLog
              }
            >
              {subjectUser?.userId}
            </UserIdLink>
          ) : (
            <UserIdLink
              to={
                routePaths.users.customerList.profile(subjectUser?.id!)
                  .historyLog
              }
            >
              {subjectUser?.userId}
            </UserIdLink>
          ),
        }}
      />
      {previousParkName && (
        <>
          <br />
          <Trans
            i18nKey="components.historyLog.templates.asset.activities.parkNameChanged"
            values={{ previous: previousParkName, current: currentParkName }}
            components={{ bold: <strong /> }}
          />
        </>
      )}
      {previousParkLatitudeLongitude && (
        <>
          <br />
          <Trans
            i18nKey="components.historyLog.templates.asset.activities.parkCoordinatesChanged"
            values={{
              previous: previousParkLatitudeLongitude,
              current: currentParkLatitudeLongitude,
            }}
            components={{ bold: <strong /> }}
          />
        </>
      )}
    </>
  );
};
