import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink, WTLinkStyled } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserConnectedToWindTurbineDto,
  UserDetailsDtoUserRoleEnum,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const UserConnectedTemplate: FC<
  UserConnectedToWindTurbineDto & {
    locationId?: number;
    currentUserRole?: UserDetailsDtoUserRoleEnum;
  }
> = ({
  locationId,
  publicId,
  assetNameForAdmin,
  assetNameForUser,
  subjectUser,
  objectUser,
  eventDateTime,
  currentUserRole,
}) => {
  const i18nKey = useMemo(() => {
    if (
      currentUserRole === UserDetailsDtoUserRoleEnum.USER_PORTAL_SUPER_ADMIN
    ) {
      return 'masterAccountConnected';
    }

    return 'userConnected';
  }, [currentUserRole]);

  const assetName = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN
        ? assetNameForAdmin
        : assetNameForUser,
    [subjectUser, assetNameForAdmin, assetNameForUser],
  );

  return (
    <Trans
      i18nKey={`components.historyLog.templates.user.activities.${i18nKey}`}
      values={{
        subjectUserId: subjectUser?.userId,
        objectUserId: objectUser?.userId,
        eventDateTime,
      }}
      components={{
        subjectUserLink: (
          <UserIdLink
            to={routePaths.users.adminList.profile(subjectUser?.id!).historyLog}
          >
            {subjectUser?.userId}
          </UserIdLink>
        ),
        objectUserLink: (
          <UserIdLink
            to={
              routePaths.users.customerList.profile(objectUser?.id!).historyLog
            }
          >
            {objectUser?.userId}
          </UserIdLink>
        ),
        assetLink: (
          <WTLinkStyled
            locationId={locationId}
            publicId={String(publicId!)}
            linkText={assetName}
          />
        ),
        bold: <strong />,
      }}
    />
  );
};
