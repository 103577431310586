import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HistoryLogItemWrapper } from './styles';
import { IHistoryLogItemProps } from './types';
import { Badge } from 'components/common/Badge';
import { BadgeSize, BadgeVariant } from 'components/common/Badge/types';
import { HistoryLogDtoTypeEnum } from 'openapi-api/admin-service';

export const HistoryLogItem: FC<IHistoryLogItemProps> = ({
  type,
  datetime,
  children,
}) => {
  const { t } = useTranslation();

  const badgeProps = useMemo((): { variant: BadgeVariant; text: ReactNode } => {
    if (type === HistoryLogDtoTypeEnum.USER_ACTIVITIES) {
      return {
        variant: BadgeVariant.Active,
        text: t('components.historyLog.activities'),
      };
    }

    if (type === HistoryLogDtoTypeEnum.STATUSES_AND_ERRORS) {
      return {
        variant: BadgeVariant.Warning,
        text: t('components.historyLog.statusesAndErrors'),
      };
    }

    return {
      variant: BadgeVariant.Error,
      text: <>{'undefined'}</>,
    };
  }, [type, t]);

  return (
    <HistoryLogItemWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Badge
          variant={badgeProps.variant}
          size={BadgeSize.Small}
          text={badgeProps.text}
          sx={{ flexShrink: 0 }}
        />
        <Typography variant="bodyS" color="grey.600" textAlign="right">
          {dayjs(datetime).format('HH:mm:ss DD.MM.YYYY')}
        </Typography>
      </Box>
      <Typography variant="bodyM">{children}</Typography>
    </HistoryLogItemWrapper>
  );
};
