import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WTLinkStyled } from 'components/shared/HistoryLog/styles';
import {
  UserChangedAssetAccelerationStatusDto,
  UserChangedAssetAccelerationStatusDtoAllOfAccelerationStatusEnum,
} from 'openapi-api/admin-service';

export const AccelerationStatusChangedTemplate: FC<
  UserChangedAssetAccelerationStatusDto & { locationId?: number }
> = ({
  accelerationStatus,
  eventDateTime,
  subjectUser,
  locationId,
  publicId,
  assetNameForAdmin,
}) => {
  const { t } = useTranslation();

  const accelerationStatusText = useMemo(
    () =>
      accelerationStatus ===
      UserChangedAssetAccelerationStatusDtoAllOfAccelerationStatusEnum.ACTIVATED
        ? t('pages.turbine.on')
        : t('pages.turbine.off'),
    [accelerationStatus, t],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.user.activities.accelerationStatusChanged"
      values={{
        status: accelerationStatusText.toUpperCase(),
        userId: subjectUser?.userId,
        eventDateTime,
      }}
      components={{
        assetLink: (
          <WTLinkStyled
            locationId={locationId}
            publicId={String(publicId!)}
            linkText={assetNameForAdmin}
          />
        ),
        bold: <strong />,
      }}
    />
  );
};
