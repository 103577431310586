import routePaths from './routePaths';
import { TranslationFunction } from '../types/general';
import { TabType } from 'components/common/Tabs/types';

export const generateTabs = (
  isSuperAdminOrAdmin: boolean,
  t: TranslationFunction,
): TabType[] => [
  { label: t`tabs.parks`, value: routePaths.parks.root },
  { label: t`tabs.reports`, value: routePaths.reports },
  ...(isSuperAdminOrAdmin
    ? [{ label: t('tabs.manageUsers'), value: routePaths.users.root }]
    : []),
  { label: t`tabs.solarPanels`, value: '/solar-panels', disabled: true },
  {
    label: t`tabs.more`,
    value: 'more',
    submenu: [
      {
        label: t`pages.investigation.title`,
        link: routePaths.investigation.root,
        pattern: new RegExp(`^${routePaths.investigation.root}`),
      },
      {
        label: t`notifications.title`,
        link: routePaths.notifications.root,
        pattern: new RegExp(`^${routePaths.notifications.root}`),
      },
    ],
  },
];
