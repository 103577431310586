import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { getRestoreTimerText } from './helpers';
import { UserStatusChip } from '../../styles';
import CommonButton from 'components/common/CommonButton';
import { renderCell } from 'components/common/Table/CommonTable/helpers';
import routePaths from 'constants/routePaths';
import {
  UserDetailsDto,
  UserDetailsDtoStatusEnum,
} from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';

const formatToReadableDatetime = (value: string | null) => {
  if (!value) return '-';

  return dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : '-';
};

type GenerateColumnsPropsType = {
  variant: 'admin' | 'customer';
  t: TranslationFunction;
  onRestoreButtonClick?: (userId?: number) => void;
};

export const generateColumns = ({
  variant,
  t,
  onRestoreButtonClick,
}: GenerateColumnsPropsType): GridColDef<UserDetailsDto>[] => [
  {
    field: 'username',
    headerName:
      variant === 'admin'
        ? t('pages.users.table.username')
        : t('pages.users.table.masterAccountName'),
    flex: 1,
    minWidth: 250,
    disableColumnMenu: true,
    renderCell: ({ value }) => <Typography variant="h5">{value}</Typography>,
  },
  {
    field: 'userRole',
    headerName: t('pages.users.table.role'),
    minWidth: variant === 'admin' ? 152 : 212,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ value }) => {
      return (
        <Typography variant="bodyM" textTransform="capitalize">
          {t(`userRoles.${value}`)}
        </Typography>
      );
    },
  },
  {
    field: 'status',
    headerName: t('pages.users.table.status'),
    minWidth: variant === 'admin' ? 152 : 212,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => (
      <UserStatusChip status={value}>
        <Typography variant="subheading">
          {value === UserDetailsDtoStatusEnum.ACTIVE
            ? t('pages.users.statuses.active')
            : t('pages.users.statuses.inactive')}
        </Typography>
      </UserStatusChip>
    ),
  },
  ...(variant === 'admin'
    ? [
        {
          field: 'email',
          headerName: t('pages.users.table.email'),
          flex: 1,
          minWidth: 250,
          sortable: false,
          disableColumnMenu: true,
          renderCell: ({ value }: GridRenderCellParams) =>
            renderCell(value || '-'),
        },
      ]
    : []),
  {
    field: 'createDatetime',
    headerName: t('pages.users.table.added'),
    minWidth: variant === 'admin' ? 148 : 196,
    disableColumnMenu: true,
    renderCell: ({ value }) => renderCell(formatToReadableDatetime(value)),
  },
  {
    field: 'lastSignInDatetime',
    headerName: t('pages.users.table.lastSignIn'),
    minWidth: variant === 'admin' ? 148 : 196,
    disableColumnMenu: true,
    renderCell: ({ value }) => renderCell(formatToReadableDatetime(value)),
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 190,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      const content =
        row.status === UserDetailsDtoStatusEnum.INACTIVE ? (
          <>
            <CommonButton
              variant="outlined"
              size="small"
              onClick={() => onRestoreButtonClick?.(row.id)}
            >
              {t('pages.users.restoreButtonText')}
            </CommonButton>
            <Typography variant="bodyS" color="grey.500" whiteSpace="nowrap">
              {getRestoreTimerText(t, row.statusUpdateDatetime)}
            </Typography>
          </>
        ) : (
          <Link
            to={
              variant === 'admin'
                ? routePaths.users.adminList.profile(row.id!).root
                : routePaths.users.customerList.profile(row.id!).root
            }
          >
            <CommonButton variant="outlined" size="small">
              {t('pages.users.profileInfoButtonText')}
            </CommonButton>
          </Link>
        );

      return (
        <Box
          display="flex"
          flexDirection="column"
          gap={0.75}
          alignItems="flex-end"
          width="100%"
        >
          {content}
        </Box>
      );
    },
  },
];
