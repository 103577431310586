import { FC } from 'react';
import { Trans } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import { UserDeletedWindTurbineDto } from 'openapi-api/admin-service';

export const WindTurbineDeletedTemplate: FC<UserDeletedWindTurbineDto> = ({
  assetNameForAdmin,
  eventDateTime,
  subjectUser,
}) => {
  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.windTurbineDeleted"
      values={{ assetName: assetNameForAdmin, eventDateTime }}
      components={{
        userLink: (
          <UserIdLink
            to={routePaths.users.adminList.profile(subjectUser?.id!).historyLog}
          >
            {subjectUser?.userId}
          </UserIdLink>
        ),
      }}
    />
  );
};
