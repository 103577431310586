import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WindTurbineStatusUpdatedDto } from 'openapi-api/admin-service';

export const WindTurbineStatusUpdatedTemplate: FC<
  WindTurbineStatusUpdatedDto
> = ({ assetNameForAdmin, assetStatus, eventDateTime }) => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.statusesAndErrors.windTurbineStatusUpdated"
      values={{
        assetName: assetNameForAdmin,
        statusName: t(`pages.turbine.statusMapping.${assetStatus}`),
        eventDateTime,
      }}
      components={{ bold: <strong /> }}
    />
  );
};
