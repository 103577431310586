import { SxProps, Theme, useMediaQuery } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { generateColumns } from './columns';
import { clickDisabledFields } from './constants';
import { AppliedFilters } from 'components/common/AppliedFilters';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import {
  CommonTable,
  TablePagination,
} from 'components/common/Table/CommonTable';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import { FilterItem, FilterValues } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

interface Props {
  variant: 'admin' | 'customer';
  fieldsToSend: string[];
  checkedFilterValues: Record<string, FilterItem>;
  filterValues: FilterValues;
  sx?: SxProps;
}

export const UsersTable: FC<Props> = ({
  variant,
  fieldsToSend,
  checkedFilterValues,
  filterValues,
}) => {
  const navigate = useNavigate();
  const { adminUserControllerApi, masterAccountControllerApi } = useApi();
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.only('desktop'),
  );
  const { t } = useTranslation();

  const { openPopup, closePopup } = useResponsePopup();

  const {
    sortModel,
    onSortModelChange,
    searchParams,
    paginationModel,
    onPaginationModelChange,
    onFilterModelChange,
  } = useDataGrid();

  const getUsers = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);

    try {
      if (variant === 'admin') {
        return (
          await adminUserControllerApi.getAdminUserDetails({
            ...objFromQuery,
          })
        ).data;
      }

      if (variant === 'customer') {
        return (
          await masterAccountControllerApi.getMasterAccountDetails({
            ...objFromQuery,
          })
        ).data;
      }
    } catch {}
  }, [
    variant,
    fieldsToSend,
    searchParams,
    adminUserControllerApi,
    masterAccountControllerApi,
  ]);

  const {
    resource: users,
    fetch: fetchUsers,
    isLoading,
  } = useAsyncResourceWithPulling({
    fetchResource: getUsers,
    pullingInterval: 30,
  });

  const openRestoreSuccessPopup = useCallback(() => {
    fetchUsers();
    openPopup({
      variant: NotificationPopupVariants.Success,
      title: t('pages.users.restoreUserPopup.successTitle'),
      primaryButton: {
        text: t('buttons.continueSession'),
        onClick: closePopup,
      },
    });
  }, [openPopup, closePopup, fetchUsers, t]);

  const restoreUser = useCallback(
    async (userId: number) => {
      try {
        if (variant === 'admin') {
          await adminUserControllerApi.restoreAdminUser({ id: userId });
          openRestoreSuccessPopup();
        }

        if (variant === 'customer') {
          await masterAccountControllerApi.restoreMasterAccount({ id: userId });
          openRestoreSuccessPopup();
        }
      } catch {
        openPopup({
          variant: NotificationPopupVariants.Error,
          title: t('pages.users.restoreUserPopup.failTitle'),
          subtitle: t('pages.users.restoreUserPopup.failSubtitle'),
          secondaryButton: {
            onClick: closePopup,
            text: t('back'),
          },
        });
      }
    },
    [
      variant,
      adminUserControllerApi,
      masterAccountControllerApi,
      openRestoreSuccessPopup,
      openPopup,
      closePopup,
      t,
    ],
  );

  const onRestoreButtonClick = useCallback(
    (userId?: number) => {
      if (typeof userId === 'undefined') return;

      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.restoreUserPopup.title'),
        subtitle:
          variant === 'admin'
            ? t('pages.users.restoreUserPopup.adminSubtitle')
            : t('pages.users.restoreUserPopup.customerSubtitle'),
        primaryButton: {
          text: t('pages.users.restoreUserPopup.submitButtonText'),
          onClick: () => restoreUser(userId),
        },
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    },
    [variant, openPopup, closePopup, restoreUser, t],
  );

  const columns = useMemo(
    () => generateColumns({ variant, t, onRestoreButtonClick }),
    [variant, t, onRestoreButtonClick],
  );

  const rows = useMemo(
    () =>
      users?.content?.map((user) => ({
        ...user,
        id: typeof user.id !== 'undefined' ? user.id : user.createDatetime,
      })) || [],
    [users?.content],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  const onCellClick = useCallback(
    ({ row, field }: GridCellParams) => {
      if (clickDisabledFields.includes(field)) return;

      if (typeof row.id !== 'undefined') {
        const path =
          variant === 'admin'
            ? routePaths.users.adminList.profile(row.id).root
            : routePaths.users.customerList.profile(row.id).root;
        navigate(path);
      }
    },
    [variant, navigate],
  );

  return (
    <>
      <AppliedFilters
        checkedFilterValues={checkedFilterValues}
        filterValues={filterValues}
        onFilterModelChange={onFilterModelChange}
        sx={{ mt: 2 }}
      />
      <CommonTable
        columns={columns}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        rowCount={users?.size}
        onCellClick={onCellClick}
        loading={isLoading}
        sx={{
          mt: 2.5,
          maxWidth: '100%',
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'grey.50',
            cursor: 'pointer',
          },
        }}
      />
      <TablePagination
        count={users?.totalElements}
        page={paginationModel.page}
        rowsPerPage={paginationModel.pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        onPaginationModelChange={onPaginationModelChange}
        sx={{
          ...(!isDesktop && {
            '& .MuiTablePagination-input': { width: 66 },
          }),
        }}
      />
    </>
  );
};
