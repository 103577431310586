import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserChangedAssetAccelerationStatusDto,
  UserChangedAssetAccelerationStatusDtoAllOfAccelerationStatusEnum,
} from 'openapi-api/admin-service';

export const AccelerationStatusChangedTemplate: FC<
  UserChangedAssetAccelerationStatusDto
> = ({ assetNameForAdmin, accelerationStatus, eventDateTime, subjectUser }) => {
  const { t } = useTranslation();

  const accelerationStatusText = useMemo(
    () =>
      accelerationStatus ===
      UserChangedAssetAccelerationStatusDtoAllOfAccelerationStatusEnum.ACTIVATED
        ? t('pages.turbine.on')
        : t('pages.turbine.off'),
    [accelerationStatus, t],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.accelerationStatusChanged"
      values={{
        assetName: assetNameForAdmin,
        status: accelerationStatusText.toUpperCase(),
        eventDateTime,
      }}
      components={{
        userLink: (
          <UserIdLink
            to={routePaths.users.adminList.profile(subjectUser?.id!).historyLog}
          >
            {subjectUser?.userId}
          </UserIdLink>
        ),
        bold: <strong />,
      }}
    />
  );
};
