import { styled } from '@mui/material';

export const HistoryLogItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  ':last-of-type': {
    borderBottom: 'none',
  },

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(2.5),
  },
}));
