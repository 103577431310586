import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WTLinkStyled } from 'components/shared/HistoryLog/styles';
import {
  UserChangedWindTurbineNameDto,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const WindTurbineDetailsChangedTemplate: FC<
  UserChangedWindTurbineNameDto & { locationId?: number }
> = ({
  eventDateTime,
  locationId,
  publicId,
  currentAssetNameForAdmin,
  currentAssetNameForUser,
  previousAssetNameForAdmin,
  previousAssetNameForUser,
  subjectUser,
}) => {
  const { t } = useTranslation();

  const changedByAdmin = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN,
    [subjectUser],
  );

  const currentAssetName = useMemo(
    () => (changedByAdmin ? currentAssetNameForAdmin : currentAssetNameForUser),
    [changedByAdmin, currentAssetNameForAdmin, currentAssetNameForUser],
  );

  const previousAssetName = useMemo(
    () =>
      changedByAdmin ? previousAssetNameForAdmin : previousAssetNameForUser,
    [changedByAdmin, previousAssetNameForAdmin, previousAssetNameForUser],
  );

  return (
    <>
      <Trans
        i18nKey="components.historyLog.templates.user.activities.windTurbineDetailsChanged"
        values={{
          userId: subjectUser?.userId,
          portal: changedByAdmin ? t('adminPortal') : t('userPortal'),
          eventDateTime,
        }}
        components={{
          assetLink: (
            <WTLinkStyled
              locationId={locationId}
              publicId={String(publicId!)}
              linkText={currentAssetName}
            />
          ),
          bold: <strong />,
        }}
      />
      <br />
      <Trans
        i18nKey="components.historyLog.templates.user.activities.windTurbineNameChanged"
        values={{ previous: previousAssetName, current: currentAssetName }}
        components={{ bold: <strong /> }}
      />
    </>
  );
};
