import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoEventsBlockWrapper } from './styles';

export const NoEventsBlock: FC = () => {
  const { t } = useTranslation();

  return (
    <NoEventsBlockWrapper>
      <Typography variant="h5">
        {t('components.historyLog.noEvents')}
      </Typography>
    </NoEventsBlockWrapper>
  );
};
