import { createContext } from 'react';

import { UserDetailsDto } from 'openapi-api/admin-service';

export interface IAdminUserContext {
  adminUser?: UserDetailsDto;
  isLoading: boolean;
  fetch: () => Promise<void>;
}

export const AdminUserContext = createContext<IAdminUserContext>(null!);
