import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MacAddressesForPark } from './components';
import { defaultValues } from './constants';
import {
  ButtonsWrapper,
  CurrentStepWrapper,
  Dialogue,
  FormWrapper,
} from './styles';
import { FormValues } from './types';
import { validationSchemas } from './validationSchema';
import routePaths from '../../../../constants/routePaths';
import useApi from '../../../../contexts/api';
import CommonButton from '../../../common/CommonButton';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { SelectOrCreateCustomer } from 'components/common/SelectOrCreateCustomer';
import { SelectOrCreatePark } from 'components/common/SelectOrCreatePark';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { UserCredentials } from 'components/shared';
import useGlobalData from 'contexts/globalData';
import useResponsePopup from 'contexts/responsePopup';
import {
  MasterAccountCreateResponseDto,
  CustomerMappingDto,
} from 'openapi-api/admin-service';
import { useUniqueEntityValidators } from 'utils/hooks/useUniqueEntityValidators';

const AssetOnboarding = () => {
  const { t } = useTranslation();
  const {
    isAssetOnboardingFormOpened,
    setIsAssetOnboardingFormOpened,
    setUpdateWTList,
  } = useGlobalData();
  const {
    assetOnboardingControllerApi,
    masterAccountControllerApi,
    locationControllerApi,
  } = useApi();
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdUser, setCreatedUser] =
    useState<MasterAccountCreateResponseDto | null>(null);
  const { openPopup, closePopup } = useResponsePopup();
  const [parkIsCreated, setParkIsCreated] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState<0 | 1 | 2>(0);

  const { isUsernameExist, isParkNameExist } = useUniqueEntityValidators();

  const schema = useMemo(() => {
    return validationSchemas[step](
      t,
      step === 0 ? isUsernameExist : isParkNameExist,
    );
  }, [isParkNameExist, isUsernameExist, step, t]);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    reset,
    watch,
    formState: { isDirty, errors },
    trigger,
    setValue,
    clearErrors,
  } = form;

  const existingCustomer = watch('existingCustomer');
  const newCustomer = watch('newCustomer');
  const existingParks = watch('existingParks');
  const newPark = watch('newPark');

  const parksForStep3 = useMemo(() => {
    if (existingParks?.length) {
      return existingParks;
    } else if (newPark?.name) {
      return [newPark.name];
    }

    return [];
  }, [existingParks, newPark?.name]);

  const closeOnboardingDialogue = useCallback(() => {
    setIsAssetOnboardingFormOpened(false);
    setStep(0);
    reset();
  }, [reset, setIsAssetOnboardingFormOpened]);

  const createMasterAccount = useCallback(
    async (username: string) => {
      if (!username) return;

      const { data } = await masterAccountControllerApi.createMasterAccount({
        masterAccountCreateRequestDto: { name: username },
      });

      setCreatedUser(data);
    },
    [masterAccountControllerApi],
  );

  const handleSubmit = form.handleSubmit(async (data: FormValues) => {
    try {
      if (!createdUser && data.newCustomer) {
        await createMasterAccount(data.newCustomer);
      }

      const owner = (data.existingCustomer || data.newCustomer) as string;

      if (!parkIsCreated && data?.newPark?.name && data?.newPark?.coordinates) {
        const [latitude, longitude] = data?.newPark.coordinates
          .replace(/ /g, '')
          ?.split(',')
          .map((coordinateStr) => Number(coordinateStr));

        await locationControllerApi.create({
          locationDto: {
            name: data?.newPark?.name,
            latitude,
            longitude,
            customerName: owner,
          },
        });

        setParkIsCreated(true);
      }

      const values: CustomerMappingDto[] =
        data.macAddresses?.flatMap((parkMacs, parkIndex) => {
          const parkName =
            data?.newPark?.name || (data?.existingParks?.[parkIndex] as string);
          return parkMacs.map(({ value }) => ({
            owner: owner,
            publicId: value,
            locationName: parkName,
          }));
        }) || [];

      await assetOnboardingControllerApi.addMapping({
        customerMappingDto: values,
      });
      setUpdateWTList(true);
      setShowSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;
        toast.error(message);

        if (message.endsWith('already exists.')) {
          if (message.startsWith('Customer')) {
            setStep(0);
            await trigger('newCustomer');
          }

          if (message.startsWith('Location with name')) {
            setStep(1);
            await trigger('newPark.name');
          }
        }
      }
    }
  });

  const canGoStep2 = useMemo(() => {
    if (existingCustomer || newCustomer) return true;
  }, [existingCustomer, newCustomer]);

  const goStep2 = useCallback(async () => {
    try {
      const isFieldValid = await trigger('newCustomer');
      if (!isFieldValid) return;
      await trigger('existingCustomer');
      setStep(1);
    } catch {}
  }, [trigger]);

  const canGoStep3 = useMemo(() => {
    if (existingParks?.length || (newPark?.name && newPark?.coordinates))
      return true;
  }, [existingParks?.length, newPark?.coordinates, newPark?.name]);

  const goStep3 = useCallback(async () => {
    try {
      await trigger('existingParks');
      if (!parkIsCreated) await trigger('newPark');

      if (!Object.keys(errors).length) {
        setValue(
          'macAddresses',
          new Array(parksForStep3.length).fill([{ value: '' }]),
        );
        setStep(2);
      }
    } catch {}
  }, [errors, parkIsCreated, parksForStep3.length, setValue, trigger]);

  useEffect(() => {
    if (showSuccess) {
      closeOnboardingDialogue();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('components.assetOnboarding.successTitle'),
        children: createdUser ? (
          <UserCredentials
            variant="customer"
            username={createdUser.name}
            password={createdUser.password}
          />
        ) : undefined,
        primaryButton: {
          text: t('continueSession'),
          onClick: () => {
            closePopup();
            setShowSuccess(false);
            navigate(routePaths.parks.root);
          },
        },
      });
    }
  }, [
    showSuccess,
    closeOnboardingDialogue,
    createdUser,
    openPopup,
    closePopup,
    navigate,
    t,
  ]);

  return (
    <Dialogue
      open={isAssetOnboardingFormOpened}
      onClose={closeOnboardingDialogue}
      fullWidth
    >
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit}>
          <Box height="100%" display="flex" flexDirection="column">
            <Typography
              variant="subheading"
              sx={{ pb: 1 }}
              color="green.700"
              component="div"
            >
              {t('step')} {`${step + 1}/3`}
            </Typography>
            <Typography variant="h2" sx={{ mb: 5 }} color="black.600">
              {t('components.assetOnboarding.title')}
            </Typography>

            <CurrentStepWrapper isCurrent={step === 0}>
              <Typography
                variant="bodyM"
                color="black.600"
                component="div"
                sx={{ pb: 3 }}
              >
                {t('components.assetOnboarding.subTitleMasterAccount')}
              </Typography>

              <SelectOrCreateCustomer />

              <ButtonsWrapper
                padding={2}
                display="flex"
                gap={2}
                justifyContent="center"
              >
                <CommonButton
                  variant="outlined"
                  onClick={closeOnboardingDialogue}
                  data-testid="close-button"
                >
                  {t('cancel')}
                </CommonButton>
                <CommonButton
                  variant="contained"
                  data-testid="submit"
                  endIcon={<ArrowRight />}
                  disabled={!canGoStep2}
                  onClick={goStep2}
                >
                  {t('next')}
                </CommonButton>
              </ButtonsWrapper>
            </CurrentStepWrapper>

            <CurrentStepWrapper isCurrent={step === 1}>
              <Typography
                variant="bodyM"
                color="black.600"
                component="div"
                sx={{ pb: 3 }}
              >
                {newCustomer
                  ? t('components.assetOnboarding.subTitleCreatePark')
                  : t('components.assetOnboarding.subTitlePark')}
              </Typography>

              <SelectOrCreatePark
                setParkIsCreated={setParkIsCreated}
                selectProps={{
                  name: 'existingParks',
                  defaultValue: [],
                  multiple: true,
                }}
              />

              <ButtonsWrapper display="flex" gap={2} justifyContent="center">
                <CommonButton
                  variant="outlined"
                  onClick={() => {
                    setValue('existingParks', []);
                    setValue('newPark', { name: '', coordinates: '' });
                    clearErrors('existingParks');
                    clearErrors('newPark');
                    setStep(0);
                  }}
                  data-testid="close-button"
                  startIcon={<ArrowLeft />}
                >
                  {t('back')}
                </CommonButton>
                <CommonButton
                  variant="contained"
                  data-testid="submit"
                  endIcon={<ArrowRight />}
                  disabled={!canGoStep3}
                  onClick={goStep3}
                >
                  {t('next')}
                </CommonButton>
              </ButtonsWrapper>
            </CurrentStepWrapper>

            {step === 2 && (
              <CurrentStepWrapper isCurrent={true}>
                <Typography
                  variant="bodyM"
                  color="black.600"
                  component="div"
                  sx={{ pb: 3 }}
                >
                  {t('components.assetOnboarding.subTitleMac')}
                </Typography>

                {parksForStep3.map((parkName, parkIndex) => (
                  <>
                    <MacAddressesForPark
                      key={`park-${parkName}-macAddresses`}
                      control={control}
                      parkName={parkName}
                      parkIndex={parkIndex}
                    />
                    {parkIndex !== parksForStep3.length - 1 && (
                      <Divider variant="fullWidth" sx={{ my: 4 }} />
                    )}
                  </>
                ))}

                <ButtonsWrapper display="flex" gap={2} justifyContent="center">
                  <CommonButton
                    variant="outlined"
                    onClick={() => {
                      setValue('macAddresses', []);
                      clearErrors('macAddresses');
                      setStep(1);
                    }}
                    data-testid="close-button"
                    startIcon={<ArrowLeft />}
                  >
                    {t('back')}
                  </CommonButton>
                  <CommonButton
                    variant="contained"
                    data-testid="submit"
                    type="submit"
                    disabled={!isDirty}
                  >
                    {t('buttons.submit')}
                  </CommonButton>
                </ButtonsWrapper>
              </CurrentStepWrapper>
            )}
          </Box>
        </FormWrapper>
      </FormProvider>
    </Dialogue>
  );
};

export default AssetOnboarding;
