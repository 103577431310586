import { CreateReportFormValues } from './types';

export const defaultValues: CreateReportFormValues = {
  customerId: '',
  locationNames: [],
  assetIds: [],
  reportPeriod: '',
  reportFromDate: '',
  reportToDate: '',
};
