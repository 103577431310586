import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsMenuButton } from '../HeadBlock/styles';
import DropdownMenu, {
  MenuItemType,
} from 'components/common/DropdownMenu/DropdownMenu';
import { ThreeDots } from 'components/icons';
import useWindTurbine from 'contexts/WindTurbine';

interface Props {
  onDisconnectMasterAccountClick: () => void;
  onDeleteAssetClick: () => void;
}

export const DesktopMenuActions: FC<Props> = ({
  onDisconnectMasterAccountClick,
  onDeleteAssetClick,
}) => {
  const { t } = useTranslation();
  const { windTurbine, showDeleteButton } = useWindTurbine();

  const items = useMemo(
    (): MenuItemType[] => [
      ...(windTurbine?.connected
        ? [
            {
              label: t('buttons.disconnectMasterAccount'),
              onClick: onDisconnectMasterAccountClick,
            },
          ]
        : []),
      ...(showDeleteButton
        ? [
            {
              label: t('pages.turbines.deleteAsset'),
              onClick: onDeleteAssetClick,
              color: 'error.main',
            },
          ]
        : []),
    ],
    [
      windTurbine,
      showDeleteButton,
      t,
      onDisconnectMasterAccountClick,
      onDeleteAssetClick,
    ],
  );

  return (
    <DropdownMenu
      title={
        <ActionsMenuButton
          variant="outlined"
          sx={{ ml: 'auto' }}
          isMobile={false}
          data-testid="desktop-actions"
        >
          <ThreeDots />
        </ActionsMenuButton>
      }
      withExpandIcon={false}
      items={items}
    />
  );
};
