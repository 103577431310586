import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { FilterValues, TranslationFunction } from 'types/general';

export const customerUsersFilterValues = (
  t: TranslationFunction,
): FilterValues => ({
  status: {
    label: t('pages.users.filters.statusFilterTitle'),
    values: [
      {
        label: t('pages.users.statuses.active'),
        value: UserDetailsDtoStatusEnum.ACTIVE,
      },
      {
        label: t('pages.users.statuses.inactive'),
        value: UserDetailsDtoStatusEnum.INACTIVE,
      },
    ],
  },
});

export const fieldsToSend = ['search', 'status', 'sort', 'size', 'page'];
