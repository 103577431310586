import { Typography, Box, ThemeProvider } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { defaultValues } from './constants';
import {
  FormWrapper,
  FormStyled,
  FormButtons,
  ClearButton,
  FieldsWrapperGeneral,
  FieldsWrapperTime,
  DateTimePickerStyled,
} from './styles';
import { GenerateInvestigationFormValues } from './types';
import CommonButton from 'components/common/CommonButton';
import { ValueProps } from 'components/common/DatePicker/DateRangePicker/DateRangePicker';
import { FormSelect } from 'components/common/form/Select';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import useApi from 'contexts/api';
import useInvestigationContext from 'contexts/investigation';
import useResponsePopup from 'contexts/responsePopup';
import { AssetDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { darkTheme } from 'styles/theme';
import { SelectItem } from 'types/general';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const GenerateInvestigationForm: FC = () => {
  const { t } = useTranslation();
  const {
    customerControllerApi,
    locationControllerApi,
    restorationRequestControllerApi,
  } = useApi();
  const { openPopup, closePopup } = useResponsePopup();
  const { customers, refreshHistory } = useInvestigationContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    customerNameParam,
    locationNameParam,
    assetNameParam,
    fromParam,
    toParam,
  ] = searchParams.values();

  const prefilledMode = useMemo(() => {
    if (
      customerNameParam &&
      locationNameParam &&
      assetNameParam &&
      fromParam &&
      toParam
    ) {
      return true;
    }
    return false;
  }, [
    assetNameParam,
    customerNameParam,
    fromParam,
    locationNameParam,
    toParam,
  ]);

  const form = useForm<GenerateInvestigationFormValues>({ defaultValues });
  const { watch, reset, setValue, formState } = form;
  const customerName = watch('customerName');
  const locationName = watch('locationName');
  const assetName = watch('assetName');
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  const getCustomerLocations = useCallback(async () => {
    if (!customerName) return [];

    try {
      return (await locationControllerApi.getAllForCustomer({ customerName }))
        .data;
    } catch {
      return [];
    }
  }, [customerName, locationControllerApi]);

  const { resource: customerLocations } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomerLocations,
    disableGlobalLoader: true,
  });

  const getCustomerAssets = useCallback(async () => {
    const customerId = customers.find(
      (customer) => String(customer?.name) === customerName,
    )?.id;

    if (typeof customerId === 'undefined' || !locationName) return [];

    try {
      return (
        await customerControllerApi.getCustomerAssetDetails({
          customerId: Number(customerId),
        })
      ).data.filter(
        (asset) => asset.locationName && locationName === asset.locationName,
      );
    } catch {
      return [];
    }
  }, [customers, locationName, customerName, customerControllerApi]);

  const { resource: customerAssets } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomerAssets,
    disableGlobalLoader: true,
  });

  const customerSelectItems = useMemo(
    (): SelectItem[] =>
      customers.map((customer) => ({
        label: String(customer.name),
        value: String(customer.name),
      })),
    [customers],
  );

  const customerLocationSelectItems = useMemo(
    () =>
      customerLocations.map(
        (location): SelectItem => ({
          label: location.name,
          value: location.name,
        }),
      ),
    [customerLocations],
  );

  const customerAssetSelectItems = useMemo(
    (): SelectItem[] =>
      customerAssets
        .filter(
          (asset) =>
            asset.status !== AssetDetailsDtoStatusEnum.CREATED &&
            asset.status !== AssetDetailsDtoStatusEnum.ONBOARDING,
        )
        .map(
          (asset): SelectItem => ({
            label: String(asset.turbineName),
            value: String(asset.turbineName),
          }),
        ),
    [customerAssets],
  );

  const isSubmitButtonDisabled = useMemo(() => {
    if (!(customerName && assetName)) {
      return true;
    }

    if (!(fromDate && toDate)) {
      return true;
    }

    const today = dayjs();
    const fromDateDayjs = dayjs(fromDate);
    const toDateDayjs = dayjs(toDate);
    if (
      fromDateDayjs.isAfter(today) ||
      toDateDayjs.isAfter(today) ||
      fromDateDayjs.isAfter(toDateDayjs)
    ) {
      return true;
    }

    return false;
  }, [assetName, customerName, fromDate, toDate]);

  const onResetButtonClick = useCallback(() => {
    reset(defaultValues);
  }, [reset]);

  const handleDateTimeChange = useCallback(
    (fieldName: ValueProps, value: Dayjs | null) => {
      const dateValue = value ? value.toISOString() : '';
      setValue(fieldName, dateValue, { shouldDirty: true });
    },
    [setValue],
  );

  const onSubmit = form.handleSubmit(
    async (data: GenerateInvestigationFormValues) => {
      const from = data.fromDate;
      const to = data.toDate;

      const assetPublicId = customerAssets.find(
        (asset) => asset?.publicId && data.assetName === asset.turbineName,
      )?.publicId;

      if (!customerName || !data.assetName || !assetPublicId || !from || !to)
        return;

      try {
        await restorationRequestControllerApi.createRestorationRequest({
          restorationRequestCreateDto: {
            assetPublicId,
            customerName: customerName,
            parkName: data.locationName,
            turbineName: data.assetName,
            startDatetime: from,
            endDatetime: to,
          },
        });

        reset(defaultValues);
        refreshHistory();

        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t('pages.investigation.dataRestoreRequested'),
          subtitle: t('pages.investigation.itMayTakeSomeTime'),
          primaryButton: {
            text: t('continueSession'),
            onClick: closePopup,
          },
        });
      } catch {}
    },
  );

  useEffect(() => {
    setValue('locationName', '');
  }, [customerName, setValue]);

  useEffect(() => {
    setValue('assetName', '');
  }, [locationName, setValue]);

  useEffect(() => {
    if (prefilledMode && customers.length && !customerName) {
      setValue('customerName', customerNameParam);
      if (!fromDate) setValue('fromDate', fromParam);
      if (!toDate) setValue('toDate', toParam);
    }
  }, [
    customers,
    customerNameParam,
    prefilledMode,
    setValue,
    fromParam,
    toParam,
    customerName,
    fromDate,
    toDate,
  ]);

  useEffect(() => {
    if (prefilledMode && customerLocations.length && !locationName) {
      setValue('locationName', locationNameParam);
    }
  }, [
    customerLocations,
    locationName,
    locationNameParam,
    prefilledMode,
    setValue,
  ]);

  useEffect(() => {
    if (prefilledMode && customerAssets.length && !assetName) {
      setValue('assetName', assetNameParam);
      setSearchParams([]);
    }
  }, [
    customerAssets,
    assetName,
    assetNameParam,
    prefilledMode,
    setValue,
    setSearchParams,
  ]);

  const [maxFromTime, minToTime, maxToTime] = useMemo(() => {
    const localFromDate = fromDate ? dayjs(fromDate) : null;
    const localToDate = toDate ? dayjs(toDate) : null;

    let localMaxFromTime, localMinToTime, localMaxToTime;

    if (localFromDate && localFromDate.isSame(dayjs(), 'day')) {
      localMaxFromTime = dayjs();
    }
    if (localToDate && localToDate.isSame(dayjs(), 'day')) {
      localMaxToTime = dayjs();
    }

    if (
      localFromDate &&
      localToDate &&
      localFromDate.isSame(localToDate, 'day')
    ) {
      localMinToTime = localFromDate;
      localMaxFromTime = localToDate;
    }

    return [localMaxFromTime, localMinToTime, localMaxToTime];
  }, [fromDate, toDate]);

  return (
    <ThemeProvider theme={darkTheme}>
      <FormWrapper>
        <FormProvider {...form}>
          <FormStyled onSubmit={onSubmit}>
            <Typography variant="h3" color="white.main">
              {t('pages.investigation.form.title')}
            </Typography>
            <Box>
              <Typography variant="subheading" color="white.main">
                {t('pages.investigation.form.generalInfoSubtitle')}
              </Typography>
              <FieldsWrapperGeneral sx={{ mt: 2.5 }}>
                <FormSelect
                  label={t('masterAccount')}
                  name="customerName"
                  items={customerSelectItems}
                  fullWidth
                />
                <FormSelect
                  label={t('pages.investigation.form.locationName')}
                  name="locationName"
                  items={customerLocationSelectItems}
                  disabled={!customerName}
                  fullWidth
                />
                <FormSelect
                  label={t('pages.investigation.form.windTurbineName')}
                  name="assetName"
                  items={customerAssetSelectItems}
                  disabled={!locationName}
                  fullWidth
                />
              </FieldsWrapperGeneral>
            </Box>
            <Box>
              <Typography variant="subheading" color="white.main">
                {t('pages.investigation.form.timePeriodSubtitle')}
              </Typography>
              <FieldsWrapperTime sx={{ mt: 2.5 }}>
                <DateTimePickerStyled
                  dateLabel={t('components.datePicker.from')}
                  onChange={(value) => handleDateTimeChange('fromDate', value)}
                  value={fromDate}
                  maxDate={dayjs(toDate || undefined)}
                  maxTime={maxFromTime}
                />
                <Typography
                  variant="bodyM"
                  color="grey.700"
                  sx={{ display: { mobile: 'none', desktop: 'block' } }}
                >
                  {'-'}
                </Typography>
                <DateTimePickerStyled
                  dateLabel={t('components.datePicker.to')}
                  onChange={(value) => handleDateTimeChange('toDate', value)}
                  value={toDate}
                  minDate={(fromDate && dayjs(fromDate)) || undefined}
                  maxDate={dayjs()}
                  minTime={minToTime}
                  maxTime={maxToTime}
                />
              </FieldsWrapperTime>
            </Box>
            <FormButtons>
              <ClearButton
                variant="text"
                size="text"
                onClick={onResetButtonClick}
                disabled={!formState.isDirty}
              >
                {t('pages.investigation.form.resetButton')}
              </ClearButton>
              <CommonButton type="submit" disabled={isSubmitButtonDisabled}>
                {t('pages.investigation.form.submitButton')}
              </CommonButton>
            </FormButtons>
          </FormStyled>
        </FormProvider>
      </FormWrapper>
    </ThemeProvider>
  );
};
