/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { HistoryLogDtoDetails } from './history-log-dto-details';

/**
 * 
 * @export
 * @interface HistoryLogDto
 */
export interface HistoryLogDto {
    /**
     * 
     * @type {number}
     * @memberof HistoryLogDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof HistoryLogDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryLogDto
     */
    'type'?: HistoryLogDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoryLogDto
     */
    'createDatetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof HistoryLogDto
     */
    'locationId'?: number;
    /**
     * 
     * @type {HistoryLogDtoDetails}
     * @memberof HistoryLogDto
     */
    'details'?: HistoryLogDtoDetails;
}

/**
    * @export
    * @enum {string}
    */
export enum HistoryLogDtoTypeEnum {
    USER_ACTIVITIES = 'USER_ACTIVITIES',
    STATUSES_AND_ERRORS = 'STATUSES_AND_ERRORS'
}


