/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';

/**
 * 
 * @export
 * @interface UserChangedAssetAccelerationStatusDtoAllOf
 */
export interface UserChangedAssetAccelerationStatusDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'event'?: UserChangedAssetAccelerationStatusDtoAllOfEventEnum;
    /**
     * 
     * @type {string}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'publicId'?: string;
    /**
     * 
     * @type {User}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'subjectUser'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'assetNameForUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'assetNameForAdmin'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'eventDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserChangedAssetAccelerationStatusDtoAllOf
     */
    'accelerationStatus'?: UserChangedAssetAccelerationStatusDtoAllOfAccelerationStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserChangedAssetAccelerationStatusDtoAllOfEventEnum {
    USER_INITIATED_WT_ONBOARDING = 'USER_INITIATED_WT_ONBOARDING',
    USER_CHANGED_WT_OPERATIONAL_STATUS = 'USER_CHANGED_WT_OPERATIONAL_STATUS',
    USER_CHANGED_WT_ACCELERATION_STATUS = 'USER_CHANGED_WT_ACCELERATION_STATUS',
    USER_CHANGED_PARK_DETAILS = 'USER_CHANGED_PARK_DETAILS',
    USER_CHANGED_WT_NAME = 'USER_CHANGED_WT_NAME',
    USER_DISCONNECTED_FROM_WT = 'USER_DISCONNECTED_FROM_WT',
    USER_CONNECTED_TO_WT = 'USER_CONNECTED_TO_WT',
    USER_DELETED_WT = 'USER_DELETED_WT',
    WT_STATUS_UPDATED = 'WT_STATUS_UPDATED',
    STATUS_CODE_RECEIVED = 'STATUS_CODE_RECEIVED',
    STATUS_CODE_RESOLVED = 'STATUS_CODE_RESOLVED',
    ERROR_CODE_RECEIVED = 'ERROR_CODE_RECEIVED',
    ERROR_CODE_RESOLVED = 'ERROR_CODE_RESOLVED'
}
/**
    * @export
    * @enum {string}
    */
export enum UserChangedAssetAccelerationStatusDtoAllOfAccelerationStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}


