import { FC } from 'react';
import { useOutlet } from 'react-router-dom';

import { AdminUserProvider } from 'contexts/adminUser';

export const AdminUserContainer: FC = () => {
  const outlet = useOutlet();

  return <AdminUserProvider>{outlet}</AdminUserProvider>;
};
