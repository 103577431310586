import { FilterTypes, FilterValues, TranslationFunction } from 'types/general';

export const getSortingItems = (t: TranslationFunction) => [
  {
    label: t('pages.turbine.historyLog.lastAdded'),
    value: 'create_datetime,DESC',
  },
  {
    label: t('pages.turbine.historyLog.firstAdded'),
    value: 'create_datetime,ASC',
  },
];

export const getFilterValues = (t: TranslationFunction): FilterValues => ({
  dateRange: {
    label: t('components.filterMenu.filterTimePeriod'),
    type: FilterTypes.dates,
    values: [],
  },
});
