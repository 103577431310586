import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ParkCardsList, StatusFilter, UnassignedTurbines } from './components';
import { parksSortingName } from './constants';
import { getRequestParams, getSortingItems } from './helpers';
import { ButtonsWrapper, MainContent, PageWrapper, TopLine } from './styles';
import { ParksSortingOrder, ParksSortingValues } from './types';
import { AppliedFilters } from 'components/common/AppliedFilters';
import { Sorting } from 'components/common/Sorting';
import {
  FilterMenu,
  Search,
  TablePagination,
  TablePaginationVariants,
} from 'components/common/Table/CommonTable';
import useApi from 'contexts/api';
import { FilterTypes, FilterValues } from 'types/general';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

const Parks = () => {
  const { t } = useTranslation();
  const { customerControllerApi, locationControllerApi } = useApi();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const {
    searchParams,
    setSearchParams,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onSearch,
    onFilterModelChange,
  } = useDataGrid();

  const getParksData = useCallback(async () => {
    const params = getRequestParams(searchParams);
    try {
      const response = (await locationControllerApi.getLocations(params)).data;

      return response;
    } catch {}
  }, [searchParams, locationControllerApi]);

  const { resource: parksData } = useAsyncResourceWithPulling({
    fetchResource: getParksData,
    pullingInterval: 30,
  });

  const getCustomers = useCallback(async () => {
    try {
      return (
        await customerControllerApi.allCustomers({ status: ['ACTIVE'] })
      ).data.map((customer) => ({
        label: customer.name?.toString() || '',
        value: customer.id?.toString() || '',
      }));
    } catch {
      return [];
    }
  }, [customerControllerApi]);

  const { resource: customers } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomers,
    disableGlobalLoader: true,
  });

  const sortingItems = useMemo(() => getSortingItems(t), [t]);

  const filterValues = useMemo(
    (): FilterValues => ({
      customerId: {
        label: t`pages.parks.filters.customerId`,
        values: customers.map((customer) => ({
          label: customer.label,
          value: customer.value,
        })),
        type: FilterTypes.select,
      },
    }),
    [t, customers],
  );

  const checkedFilterValues = useMemo(
    () => ({
      customerId: { value: searchParams.get('customerId') },
    }),
    [searchParams],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.get(parksSortingName) === null) {
          prev.set(
            parksSortingName,
            `${ParksSortingValues.addedDatetime},${ParksSortingOrder.desc}`,
          );
        }
        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return (
    <PageWrapper>
      <TopLine>
        <Typography color="white.main" variant="h1">
          {t`pages.parks.title`}
        </Typography>
        <ButtonsWrapper>
          <Search onSearch={onSearch} />
          <Sorting
            sortingName={parksSortingName}
            items={sortingItems}
            onSortModelChange={onSortModelChange}
          />
          <FilterMenu
            checkedFilterValues={checkedFilterValues}
            filterValues={filterValues}
            onFilterModelChange={onFilterModelChange}
          />
        </ButtonsWrapper>
      </TopLine>
      <StatusFilter
        counters={parksData?.totalLocationCounts}
        onFilterModelChange={onFilterModelChange}
      />
      <MainContent>
        <UnassignedTurbines
          unassignedTurbineCount={parksData?.unassignedTurbineCount}
        />
        <AppliedFilters
          checkedFilterValues={checkedFilterValues}
          filterValues={filterValues}
          onFilterModelChange={onFilterModelChange}
        />
        <ParkCardsList items={parksData?.content} />
        <TablePagination
          variant={TablePaginationVariants.DEFAULT}
          count={parksData?.totalElements}
          page={paginationModel.page}
          rowsPerPage={paginationModel.pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          onPaginationModelChange={onPaginationModelChange}
          sx={{
            mt: 1.5,
            ...(!isDesktop && {
              '& .MuiTablePagination-input': { width: 66 },
            }),
          }}
        />
      </MainContent>
    </PageWrapper>
  );
};

export default Parks;
