import { styled } from '@mui/material';

export const NoEventsBlockWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(5, 3),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '12px',

  '& > .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '130%',
    color: theme.palette.grey[400],
  },
}));
